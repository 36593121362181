import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Card, Col, Form, Input, Row } from "antd";
import firebase from "firebase";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { componentStyles } from "../utils";

const LoginPage: React.FC = () => {
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>("");

  const handlePasswordChange = (ev: ChangeEvent<HTMLInputElement>) => {
    if (error) {
      setError("");
    }
    setPassword(ev.target.value);
  };

  const signIn = useCallback(async () => {
    setLoading(true);
    try {
      await firebase
        .auth()
        .signInWithEmailAndPassword(
          "manut-dashboard@novaa3-app.com.br",
          password
        );
    } catch (e) {
      if (e.code === "auth/wrong-password") {
        setError("Senha incorreta.");
      } else {
        setError("Algo deu errado.");
      }
      setPassword("");
      setLoading(false);
    }
  }, [password]);

  useEffect(() => {
    if (password.length === 6) {
      signIn();
    }
  }, [password, signIn]);

  return (
    <div style={styles.screen}>
      <Row style={{ width: "100%" }} justify="center">
        <Col xs={20} md={12}>
          <Card>
            <Form.Item
              hasFeedback
              validateStatus={
                error ? "error" : loading ? "validating" : undefined
              }
              help={error ? error : loading ? "Entrando..." : undefined}
              style={loading || error ? undefined : styles.formItem}
            >
              <Input.Password
                placeholder="Senha de acesso"
                value={password}
                onChange={handlePasswordChange}
                disabled={password.length === 6}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                autoFocus
              />
            </Form.Item>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const styles = componentStyles({
  screen: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    width: "100vw",
    backgroundColor: "#103D64",
    color: "#EEE",
  },
  inputContainer: {
    width: "30%",
  },
  formItem: {
    marginBottom: 0,
  },
});

export default LoginPage;
