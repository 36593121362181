import { Col, notification, Row } from "antd";
import firebase from "firebase";
import { useEffect } from "react";
import { DashboardCol, PageContainer } from "../components";
import CONSTANTS from "../constants";
import { useTickets } from "../hooks";
import { FsTicket } from "../types";
import { componentStyles, filterTicketsByStatus } from "../utils";

type DashboardPageProps = {
  displayMode: boolean;
};

const DashboardPage: React.FC<DashboardPageProps> = ({ displayMode }) => {
  const [tickets] = useTickets();

  const ticketsByStatus = filterTicketsByStatus(tickets);

  useEffect(() => {
    firebase
      .firestore()
      .collection("tickets")
      .onSnapshot((snapshot) => {
        if (snapshot.size > 5) {
          return;
        }

        snapshot.docChanges().forEach((change) => {
          const ticket = change.doc.data() as FsTicket;

          if (change.type === "added") {
            notification.warn({
              message: `Nova OS – #${ticket.id}`,
              description: ticket.description,
            });
          } else if (
            change.type === "modified" &&
            ticket.status === "pending"
          ) {
            notification.error({
              message: `Solução recusada (OS #${ticket.id})`,
              description: ticket.refusalReason,
            });
          }
        });
      });
  }, []);

  return (
    <PageContainer
      title="Dashboard"
      style={{ padding: 0 }}
      titleContainerStyle={{
        paddingTop: CONSTANTS.STYLE.SPACING.MD,
        paddingLeft: CONSTANTS.STYLE.SPACING.MD,
        paddingRight: CONSTANTS.STYLE.SPACING.MD,
      }}
    >
      <Row>
        <Col span={6}>
          <DashboardCol
            title="Pendentes"
            items={ticketsByStatus["pending"]}
            color={CONSTANTS.STYLE.COLORS.TICKET_STATUS.PENDING}
            idx={0}
            statisticContainerStyle={
              displayMode ? { borderBottom: undefined } : undefined
            }
            ticketsContainerStyle={
              displayMode ? styles.displayModeTicketsContainer : undefined
            }
          />
        </Col>
        <Col span={6}>
          <DashboardCol
            title="Resolvendo"
            items={ticketsByStatus["solving"].sort(
              (tA, tB) =>
                ({ low: 1, medium: 2, high: 3 }[tB.priority!] -
                { low: 1, medium: 2, high: 3 }[tA.priority!])
            )}
            color={CONSTANTS.STYLE.COLORS.TICKET_STATUS.SOLVING}
            idx={1}
            statisticContainerStyle={
              displayMode ? { borderBottom: undefined } : undefined
            }
            ticketsContainerStyle={
              displayMode ? styles.displayModeTicketsContainer : undefined
            }
          />
        </Col>
        <Col span={6}>
          <DashboardCol
            title="Solucionadas"
            items={ticketsByStatus["solved"]}
            color={CONSTANTS.STYLE.COLORS.TICKET_STATUS.SOLVED}
            idx={2}
            statisticContainerStyle={
              displayMode ? { borderBottom: undefined } : undefined
            }
            ticketsContainerStyle={
              displayMode ? styles.displayModeTicketsContainer : undefined
            }
          />
        </Col>
        <Col span={6}>
          <DashboardCol
            title="Encerradas"
            items={ticketsByStatus["closed"].reverse()}
            color={CONSTANTS.STYLE.COLORS.TICKET_STATUS.CLOSED}
            idx="last"
            statisticContainerStyle={
              displayMode ? { borderBottom: undefined } : undefined
            }
            ticketsContainerStyle={
              displayMode ? styles.displayModeTicketsContainer : undefined
            }
          />
        </Col>
      </Row>
    </PageContainer>
  );
};

const styles = componentStyles({
  displayModeTicketsContainer: {
    paddingTop: 5,
  },
});

export default DashboardPage;
