import { ConfigProvider } from "antd";
import "antd/dist/antd.css";
import locale from "antd/lib/locale/pt_BR";
// import "bootstrap/dist/css/bootstrap.min.css";
import firebase from "firebase";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import store from "./store";
import { setUser } from "./store/actions/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAynKF5joA-_wpax9jzatonSZgxSE-MaRQ",
  authDomain: "nova-a3-ind.firebaseapp.com",
  projectId: "nova-a3-ind",
  storageBucket: "nova-a3-ind.appspot.com",
  messagingSenderId: "810900069450",
  appId: "1:810900069450:web:1c84966ee298b9c8c59ab3",
  measurementId: "G-04P7RVKWBN",
};

firebase.initializeApp(firebaseConfig);

firebase.auth().onAuthStateChanged(async (user) => {
  await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
  store.dispatch(setUser(user));
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider locale={locale}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ConfigProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
