import { DatePicker, Form } from "antd";
import moment, { Moment } from "moment";
import React from "react";
import { componentStyles } from "../../utils";

type BIStepDatePickerProps = {
  label: string;
  startDate: Moment;
  endDate: Moment;
  handleChange: (values: [Moment, Moment]) => void;
};

const BIStepDatePicker: React.FC<BIStepDatePickerProps> = ({
  label,
  startDate,
  endDate,
  handleChange,
}) => {
  return (
    <Form layout="vertical">
      <Form.Item
        label={label}
        tooltip={{
          placement: "topLeft",
          title: "Selecione as datas de início e final do BI",
        }}
      >
        <DatePicker.RangePicker
          ranges={{
            "Este mês": [moment().startOf("month"), moment()],
            "Esta semana": [moment().startOf("week"), moment()],
            "Últimos 15 dias": [moment().subtract(2, "weeks"), moment()],
            "Últimos 30 dias": [moment().subtract(1, "month"), moment()],
            "Desde o início": [moment("01/01/2000"), moment()],
          }}
          value={[startDate, endDate]}
          onChange={(values) => handleChange(values as [Moment, Moment])}
          format="DD/MM/YYYY"
          disabledDate={(date) =>
            date.isAfter(moment()) || date.isBefore(moment("01/01/2000"))
          }
          allowClear={false}
          autoFocus
          style={styles.datePicker}
        />
      </Form.Item>
    </Form>
  );
};

const styles = componentStyles({
  datePicker: {
    width: "100%",
  },
});

export default BIStepDatePicker;
