import { DataAction, DataState } from "../../types";

const initialState: DataState = {
  departments: [],
  loading: true,
};

const reducer = (state = initialState, action: DataAction) => {
  switch (action.type) {
    case "DATA_SET_DEPARTMENTS":
      return { ...state, departments: action.payload.departments };
    case "DATA_SET_LOADING":
      return { ...state, loading: action.payload.loading };
    default:
      return state;
  }
};

export default reducer;
