import { LoadingIndicator } from "../components";
import { componentStyles } from "../utils";

const LoadingPage: React.FC = () => {
  return (
    <div style={styles.screen}>
      <LoadingIndicator iconColor="#FFF" />
    </div>
  );
};

const styles = componentStyles({
  screen: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: "#103D64",
    color: "#EEE",
  },
});

export default LoadingPage;
