import CONSTANTS from "../../constants";
import { FsTicket } from "../../types";
import { componentStyles } from "../../utils";
import classNames from "./Dashboard.module.css";
import DashboardStatistic from "./DashboardStatistic";
import TicketCard from "./TicketCard";

type DashboardColProps = {
  title: string;
  items: FsTicket[];
  color: string;
  idx: number | "last";
  statisticContainerStyle?: React.CSSProperties;
  ticketsContainerStyle?: React.CSSProperties;
};

const DashboardCol: React.FC<DashboardColProps> = ({
  title,
  items,
  color,
  idx,
  statisticContainerStyle,
  ticketsContainerStyle,
}) => {
  return (
    <div>
      <div
        style={{
          paddingLeft: idx === 0 ? CONSTANTS.STYLE.SPACING.MD : undefined,
          paddingRight:
            idx === "last"
              ? CONSTANTS.STYLE.SPACING.MD
              : CONSTANTS.STYLE.SPACING.SM,
          paddingBottom: CONSTANTS.STYLE.SPACING.SM,
          borderBottom: "1px solid #f0f0f0",
          ...statisticContainerStyle,
        }}
      >
        <DashboardStatistic title={title} value={items.length} color={color} />
      </div>

      <div
        className={classNames.TicketsContainer}
        style={{
          ...styles.itemsContainer,
          paddingLeft: idx === 0 ? CONSTANTS.STYLE.SPACING.MD : undefined,
          paddingRight:
            idx === "last"
              ? CONSTANTS.STYLE.SPACING.MD
              : CONSTANTS.STYLE.SPACING.SM,
          ...ticketsContainerStyle,
        }}
      >
        {items.map((t) => (
          <TicketCard key={t.id} ticket={t} style={styles.item} />
        ))}
      </div>
    </div>
  );
};

const styles = componentStyles({
  itemsContainer: {
    height: `calc(100vh - 284px)`,
    overflow: "scroll",
    paddingTop: CONSTANTS.STYLE.SPACING.MD,
    paddingBottom: CONSTANTS.STYLE.SPACING.SM * 2,
  },
  item: {
    marginBottom: CONSTANTS.STYLE.SPACING.SM,
  },
});

export default DashboardCol;
