import moment from "moment";
import { FsTicket } from "../../types";

export const timeDiffBetweenTicketEvents = (
  laterEvent: FsTicket["events"][0],
  earlierEvent: FsTicket["events"][0]
) =>
  moment(laterEvent.timestamp).diff(
    moment(earlierEvent.timestamp),
    "hours",
    true
  );

export const calcMachineStoppedHours = (ticket: FsTicket) => {
  if (!ticket.interruptions.equipment) {
    return 0;
  }

  const solutionTransmittedEvents = ticket.events.filter(
    (ev) => ev.type === "solutionTransmitted"
  );

  let totalHours: number = 0;

  if (solutionTransmittedEvents.length === 0) {
    totalHours = moment().diff(moment(ticket.createdAt), "hours", true);
  } else {
    const ticketCreatedEvents = ticket.events.filter(
      (ev) => ev.type === "ticketCreated"
    );
    const ticketReopenedEvents = ticket.events.filter(
      (ev) => ev.type === "ticketReopened"
    );

    totalHours = solutionTransmittedEvents.reduce(
      (acc, solutionTransmittedEv, idx) =>
        acc +
        timeDiffBetweenTicketEvents(
          solutionTransmittedEv,
          [...ticketCreatedEvents, ...ticketReopenedEvents][idx]
        ),
      0
    );

    totalHours += ticketReopenedEvents.reduce(
      (acc, ticketReopenedEv, idx) =>
        acc +
        timeDiffBetweenTicketEvents(
          ticketReopenedEv,
          solutionTransmittedEvents[idx]
        ),
      0
    );
  }

  if (ticket.interruptions.line) {
    // Multiply by number of machines in line
  }

  return totalHours;
};

export const calcProductionStoppedHours = (ticket: FsTicket) => {
  if (
    !ticket.interruptions.production ||
    ticket.maintenanceType === "preventiva"
  ) {
    return 0;
  }

  return calcMachineStoppedHours(ticket);
};

export const getTicketHourlyProdRate = (ticket: FsTicket) => {
  switch (ticket.username) {
    case "kit-automatico":
    case "off-set":
      return 6600;
    case "flexografia-papel":
    case "flexografia-plastico":
      return 4800;
    case "corte-solda-luva":
      return 3900;
    case "corte-solda-saco":
      return 3600;
    case "extrusao":
      switch (ticket.machine) {
        case "1":
          return 150;
        default:
          return 50;
      }
    default:
      return 0;
  }
};

export const calcTicketLoss = (ticket: FsTicket) => {
  return getTicketHourlyProdRate(ticket) * calcProductionStoppedHours(ticket);
};

export const getTicketShift = (
  ticket: FsTicket
): "A" | "B" | "C" | "D" | "E" | "P" => {
  // Turno	Entrada 	Saída
  // A	     5:20	    13:40
  // B	    13:40	    22:00
  // C	    22:00	     5:20
  // D	     7:00	    19:00
  // P	     7:00	    15:20
  // E	    19:00	     7:00

  const h = moment(ticket.createdAt).hours();
  const m = moment(ticket.createdAt).minutes();
  const s = moment(ticket.createdAt).seconds();

  const time = s + m * 60 + h * 60 * 60;

  if (time >= 5 * 60 * 60 + 20 * 60 && time < 13 * 60 * 60 + 40 * 60) {
    return "A";
  }
  if (time >= 13 * 60 * 60 + 40 * 60 && time < 22 * 60 * 60) {
    return "B";
  } else {
    return "C";
  }
};
