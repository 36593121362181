import {
  FilePdfOutlined,
  TableOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { Card, message, Tag, Tooltip, Typography } from "antd";
import moment from "moment";
import "moment/locale/pt-br";
import QRCodeStyling from "qr-code-styling";
import { useHistory } from "react-router-dom";
import { Report } from "../../classes";
import CONSTANTS from "../../constants";
import { FsBI } from "../../types";
import { componentStyles, translateReportInputs } from "../../utils";

type ReportCardProps = {
  data: FsBI;
  hideButtons?: boolean;
};

const ReportCard: React.FC<ReportCardProps> = ({
  data: {
    internalId,
    tickets,
    dateRange,
    departments,
    machines,
    shifts,
    problems,
    issuedAt,
    extras,
    qrCode,
  },
  hideButtons,
}) => {
  const history = useHistory();

  const inputTranslations = translateReportInputs({
    departments,
    machines,
    shifts,
    problems,
    dateRange,
  });

  const cardDescription = `${inputTranslations.departments!.long} • ${
    inputTranslations.machines!.long
  } • ${inputTranslations.shifts!.long} • ${inputTranslations.problems!.long}`;

  const downloadReport = async (format: "csv" | "pdf") => {
    message.loading({
      content: "Preparando...",
      key: `loading-${internalId}`,
    });

    const reportConfig: FsBI = {
      internalId,
      tickets,
      dateRange,
      extras,
      departments,
      machines,
      shifts,
      problems,
      issuedAt,
      qrCode,
    };

    const pdfQrCode = await new QRCodeStyling({
      data: reportConfig.qrCode.data,
      backgroundOptions: {
        color: "#003C68",
      },
      dotsOptions: {
        color: "#D8EEF9",
      },
    }).getRawData();

    try {
      const generatedReport = new Report(reportConfig.internalId, tickets, {
        qrCodeURI: URL.createObjectURL(pdfQrCode),
        issuedAt: reportConfig.issuedAt,
        dateRange: [
          moment(dateRange[0].toDate()),
          moment(dateRange[1].toDate()),
        ],
        extras,
        departments,
        machines,
        shifts,
        problems,
      });

      generatedReport[format]();

      message.success(`Download concluído`);
    } catch (e) {
      console.error(e);
      message.error(`Algo deu errado`);
    } finally {
      message.destroy(`loading-${internalId}`);
    }
  };

  return (
    <Card
      style={styles.card}
      actions={
        hideButtons
          ? undefined
          : [
              <Tooltip title="Ver OS relacionadas">
                <UnorderedListOutlined
                  key={`${internalId}-list-tickets`}
                  onClick={() =>
                    history.push(
                      `/bi?id=${internalId.toString().padStart(4, "0")}`
                    )
                  }
                />
              </Tooltip>,
              <Tooltip title="Baixar em PDF">
                <FilePdfOutlined
                  key={`${internalId}-download-pdf`}
                  onClick={() => downloadReport("pdf")}
                />
              </Tooltip>,
              <Tooltip title="Baixar em CSV">
                <TableOutlined
                  key={`${internalId}-download-csv`}
                  onClick={() => downloadReport("csv")}
                />
              </Tooltip>,
            ]
      }
    >
      <Card.Meta
        title={
          <div>
            <div style={styles.headerInfo}>
              <Tag color="#2db7f5">
                {moment(dateRange[0].toDate()).format("DD/MM/YY")}–
                {moment(dateRange[1].toDate()).format("DD/MM/YY")}
              </Tag>
              <small>
                <Typography.Text
                  italic
                  type="secondary"
                  style={styles.generatedAtContainer}
                >
                  Emitido{" "}
                  {moment(issuedAt.toDate()).locale("pt-br").from(moment())}
                </Typography.Text>
              </small>
            </div>
            <span style={styles.titleContainer}>
              <Typography.Title level={4} style={styles.title}>
                #{internalId.toString().padStart(4, "0")}
              </Typography.Title>
              <Typography.Text italic type="secondary">
                ({tickets.length} OS no relatório)
              </Typography.Text>
            </span>
          </div>
        }
        description={cardDescription}
      />
    </Card>
  );
};

const styles = componentStyles({
  card: {
    marginBottom: CONSTANTS.STYLE.SPACING.SM,
    marginLeft: CONSTANTS.STYLE.SPACING.MD,
    marginRight: CONSTANTS.STYLE.SPACING.MD,
  },
  headerInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: CONSTANTS.STYLE.SPACING.XS,
  },
  generatedAtContainer: {
    fontWeight: "normal",
  },
  generatedAtValue: {
    marginLeft: CONSTANTS.STYLE.SPACING.SM,
  },
  titleContainer: {
    display: "flex",
    alignItems: "baseline",
  },
  title: {
    marginRight: CONSTANTS.STYLE.SPACING.SM,
    marginBottom: 0,
  },
});

export default ReportCard;
