import { Button, notification, Result } from "antd";
import firebase from "firebase";
import moment from "moment";
import QRCodeStyling from "qr-code-styling";
import { useCallback, useEffect, useState } from "react";
import { Report } from "../../classes";
import { FsBI, FsTicket, ReportConfig } from "../../types";
import { componentStyles } from "../../utils";
import LoadingIndicator from "../LoadingIndicator";

type BIStepReportProps = {
  tickets?: FsTicket[];
  departments: string[];
  machines: { [key: string]: string[] };
  shifts: { [key: string]: string[] };
  problems: { [key: string]: string[] };
  dateRange: [moment.Moment, moment.Moment];
  extras: ReportConfig["extras"];
};

const BIStepReport: React.FC<BIStepReportProps> = ({
  tickets,
  departments,
  machines,
  shifts,
  problems,
  dateRange,
  extras,
}) => {
  const [report, setReport] = useState<Report | null>();
  const [isLoadingCsv, setIsLoadingCsv] = useState(false);

  const prepareReport = useCallback(async () => {
    document.body.style.cursor = "wait";
    notification.destroy();

    if (!tickets || tickets.length === 0) {
      notification.error({
        message: "Nenhuma OS relacionada",
        description:
          "Seu relatório deve ser capaz de relacionar pelo menos uma OS – por favor, revise sua configuração.",
      });
      setReport(null);
      return;
    }

    const reportId =
      (await firebase.firestore().collection("manut-bi").get()).docs
        .map((doc) => doc.data() as FsBI)
        .sort((docA, docB) => docB.internalId - docA.internalId)[0]
        ?.internalId + 1 || 1;

    const reportConfig: FsBI = {
      internalId: reportId,
      tickets,
      dateRange: [
        firebase.firestore.Timestamp.fromDate(dateRange[0].toDate()),
        firebase.firestore.Timestamp.fromDate(dateRange[1].toDate()),
      ],
      extras,
      departments,
      machines,
      shifts,
      problems,
      issuedAt: firebase.firestore.Timestamp.now(),
      qrCode: {
        data: `https://manutencao.novaa3.com.br/bi?id=${reportId
          .toString()
          .padStart(4, "0")}`,
      },
    };

    const qrCode = await new QRCodeStyling({
      data: reportConfig.qrCode.data,
      backgroundOptions: {
        color: "#003C68",
      },
      dotsOptions: {
        color: "#D8EEF9",
      },
    }).getRawData();

    try {
      const generatedReport = new Report(reportConfig.internalId, tickets, {
        qrCodeURI: URL.createObjectURL(qrCode),
        issuedAt: reportConfig.issuedAt,
        dateRange,
        extras,
        departments,
        machines,
        shifts,
        problems,
      });
      await firebase.firestore().collection("manut-bi").add(reportConfig);
      setReport(generatedReport);

      notification.success({
        message: "Seu relatório está pronto!",
        description: `${tickets.length} OS relacionadas.`,
        key: "manut-bi-report-success",
      });
    } catch (e) {
      console.error(e);
      notification.error({
        message: "Algo deu errado",
        description: "Não foi possível gerar o relatório.",
        key: "manut-bi-report-error",
      });
      setReport(null);
    } finally {
      document.body.style.cursor = "default";
    }
  }, [tickets, dateRange, departments, problems, shifts, machines, extras]);

  useEffect(() => {
    prepareReport();
  }, [prepareReport]);

  return (
    <div style={styles.content}>
      {report === undefined ? (
        <LoadingIndicator text="Preparando relatório..." />
      ) : report === null ? undefined : (
        <Result
          status="success"
          title="Seu relatório está pronto!"
          subTitle="Escolha o formato de download"
          extra={[
            <Button
              type="primary"
              onClick={() => {
                report.pdf();
              }}
              key="pdf"
            >
              PDF
            </Button>,
            <Button
              key="csv"
              loading={isLoadingCsv}
              onClick={async () => {
                setIsLoadingCsv(true);
                await report.csv();
                setIsLoadingCsv(false);
              }}
            >
              {isLoadingCsv ? "Baixando..." : "CSV"}
            </Button>,
          ]}
        />
      )}
    </div>
  );
};

const styles = componentStyles({
  content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "70%",
  },
});

export default BIStepReport;
