import { LoadingOutlined } from "@ant-design/icons";
import { Spin, Typography } from "antd";
import React from "react";
import CONSTANTS from "../constants";
import { componentStyles } from "../utils";

type LoadingIndicatorProps = {
  text?: string;
  iconColor?: string;
};

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
  text,
  iconColor,
}) => {
  return (
    <div style={styles.container}>
      <Spin
        indicator={
          <LoadingOutlined style={{ fontSize: 18, color: iconColor }} spin />
        }
      />
      <Typography.Text style={styles.text}>
        {text ? text : "Carregando..."}
      </Typography.Text>
    </div>
  );
};

const styles = componentStyles({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    marginLeft: CONSTANTS.STYLE.SPACING.SM,
    color: "inherit",
  },
});

export default LoadingIndicator;
