import {
  Button,
  Col,
  message,
  Popconfirm,
  Result,
  Row,
  Typography
} from "antd";
import firebase from "firebase";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch, useLocation } from "react-router-dom";
import { AppHeader, GlobalLoading } from "./components";
import { useAuth, useData, useQuery } from "./hooks";
import {
  BIPage,
  DashboardPage,
  LoadingPage,
  LoginPage,
  ReportsPage
} from "./pages";
import { setDataLoading, setDepartments } from "./store/actions/data";
import { Na3Dpt } from "./types";
import { componentStyles } from "./utils";

const App: React.FC = () => {
  const { loading } = useData();
  const { user } = useAuth();
  const [displayMode, setDisplayMode] = useState<boolean>();
  const [forceLoadWebsite, setForceLoadWebsite] = useState(false);
  const location = useLocation();
  const query = useQuery();
  const dispatch = useDispatch();

  /*
  useEffect(() => {
    const onSpaceDown = (ev: KeyboardEvent) => {
      if (user) {
        ev.preventDefault();
        if (ev.key === " " && location.pathname === "/") {
          setDisplayMode((currState) => !currState);
        }
      }
    };

    document.addEventListener("keydown", onSpaceDown);

    return () => {
      document.removeEventListener("keydown", onSpaceDown);
    };
  }, [location.pathname, user]);
  */

  useEffect(() => {
    const getDepartments = async () => {
      dispatch(setDataLoading(true));

      const docs = (await firebase.firestore().collection("departments").get())
        .docs;
      const dpts = docs
        .map((d) => d.data() as Na3Dpt)
        .filter(
          (dpt) =>
            dpt.type === "shop-floor" && Object.keys(dpt.apps).includes("manut")
        )
        .sort((a, b) =>
          a.name.localeCompare(b.name)
        ) as (Na3Dpt<"shop-floor"> & { manutIssues: string[] })[];

      dpts.forEach((dpt) => {
        const initialManutIssues: string[] = [];
        if (!dpt.machines) {
          dpt.manutIssues = initialManutIssues;
        }
        dpt.manutIssues = Object.values(dpt.machines).reduce((acc, machine) => {
          const updatedAcc = [...acc];
          machine.issues.forEach((issue) => {
            if (!updatedAcc.includes(issue)) {
              updatedAcc.push(issue);
            }
          });
          return updatedAcc;
        }, initialManutIssues);
      });

      dispatch(setDepartments(dpts));
      dispatch(setDataLoading(false));
    };

    getDepartments();
  }, [dispatch]);

  useEffect(() => {
    if (location.pathname !== "/") {
      return;
    }

    if (displayMode) {
      message.info("Modo display ativado");
    } else if (displayMode === false) {
      message.info("Modo display desativado");
    }
  }, [displayMode, location]);

  useEffect(() => {
    if (location.pathname === "/bi" && query.get("id")) {
      setForceLoadWebsite(true);
    }

    return () => {
      if (location.pathname === "/bi" && query.get("id")) {
        setForceLoadWebsite(false);
      }
    };
  }, [location, query]);

  if (user === undefined) {
    return <LoadingPage />;
  } else if (user === null) {
    return <LoginPage />;
  }
  return (
    <Row style={styles.page}>
      {!forceLoadWebsite && (
        <Col xs={24} md={0}>
          <div style={styles.unsupportedDevice}>
            <Result
              status="error"
              title="Aparelho não suportado"
              subTitle="Por favor, acesse utilizando um navegador no computador"
              extra={[
                <Popconfirm
                  title={
                    <>
                      <div>
                        <Typography.Text>
                          Tem certeza que deseja prosseguir para o site mesmo
                          assim?
                        </Typography.Text>
                      </div>
                      <div style={{ marginTop: 5 }}>
                        <Typography.Text italic type="secondary">
                          A plataforma pode não se comportar como deve.
                        </Typography.Text>
                      </div>
                    </>
                  }
                  onConfirm={() => setForceLoadWebsite(true)}
                  okText="Sim"
                  cancelText="Não"
                  overlayStyle={{ marginLeft: 20, marginRight: 20 }}
                >
                  <Button type="link" key="proceedToWebsite">
                    Clique aqui se você acha que isso foi um erro
                  </Button>
                </Popconfirm>,
              ]}
            />
          </div>
        </Col>
      )}

      <Col
        xs={forceLoadWebsite ? 24 : 0}
        md={24}
        style={{ height: "100%", overflow: "hidden" }}
      >
        <AppHeader
          buttons={
            displayMode
              ? undefined
              : location.pathname === "/bi" && query.get("id")
              ? [
                  {
                    label: "Voltar para Relatórios",
                    route: "/relatorios",
                    type: "primary",
                  },
                ]
              : [
                  { label: "BI", route: "/bi", type: "primary" },
                  { label: "Relatórios", route: "/relatorios" },
                ]
          }
        />

        <div style={{ height: "100%", overflow: "hidden" }}>
          <Switch location={location}>
            <Route path="/bi" component={BIPage} />
            <Route path="/relatorios" component={ReportsPage} />
            <Route path="/">
              <DashboardPage displayMode={!!displayMode} />
            </Route>
          </Switch>
        </div>

        <GlobalLoading show={loading} />
      </Col>
    </Row>
  );
};

const styles = componentStyles({
  page: {
    height: "100vh",
    overflow: "hidden",
  },
  unsupportedDevice: {
    height: "80vh",
    display: "flex",
    alignItems: "center",
  },
});

export default App;
