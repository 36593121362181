import { Button, Col, Form, Row, Select } from "antd";
import React from "react";
import CONSTANTS from "../../constants";
import { componentStyles } from "../../utils";

type BIStepSelectProps = {
  items: {
    label: string;
    options: {
      value: string;
      label: string;
    }[];
    value: string[];
    handleChange: (val: string[]) => void;
    isLoading?: boolean;
    tooltip?: React.ReactNode;
    style?: { bgColor?: string; txtColor?: string };
  }[];
};

const BIStepSelect: React.FC<BIStepSelectProps> = ({ items }) => {
  return (
    <Form layout="vertical">
      {items.map((item, itemIdx) => (
        <Row
          align="bottom"
          gutter={CONSTANTS.STYLE.SPACING.SM}
          style={styles.formRow}
          key={`${item.label}-${itemIdx}`}
        >
          <Col
            span={
              item.options.length !== 0 && item.value.length === 0 ? 21 : 24
            }
          >
            <Form.Item
              label={item.label}
              hasFeedback
              validateStatus={
                item.isLoading
                  ? "validating"
                  : item.value.length === 0 && item.options.length !== 0
                  ? "error"
                  : "success"
              }
              help={
                item.isLoading
                  ? "Carregando..."
                  : item.value.length === 0 && item.options.length !== 0
                  ? "Você deve selecionar pelo menos um item."
                  : undefined
              }
              tooltip={
                item.tooltip
                  ? { placement: "topLeft", title: item.tooltip }
                  : undefined
              }
              style={styles.formItem}
            >
              <Select
                mode="multiple"
                placeholder={
                  item.options.length === 0
                    ? "Nenhuma opção disponível"
                    : "Clique para escolher"
                }
                value={item.value}
                onChange={item.handleChange}
                style={styles.select}
                autoFocus={itemIdx === 0}
                disabled={item.options.length === 0}
                allowClear
              >
                {item.options.map((option, optionIdx) => (
                  <Select.Option
                    value={option.value}
                    key={`${option.value}-${optionIdx}`}
                  >
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          {item.options.length !== 0 && item.value.length === 0 && (
            <Col span={3} style={styles.toggleAllBtn}>
              <Button
                block
                onClick={() => {
                  item.handleChange(item.options.map((option) => option.value));
                }}
              >
                Todos
              </Button>
            </Col>
          )}
        </Row>
      ))}
    </Form>
  );
};

const styles = componentStyles({
  select: {
    width: "100%",
  },
  formRow: {
    marginBottom: CONSTANTS.STYLE.SPACING.MD + CONSTANTS.STYLE.SPACING.XS,
  },
  formItem: {
    marginBottom: 0,
  },
  toggleAllBtn: {
    marginBottom: 23.5,
  },
});

export default BIStepSelect;
