import { AuthActionSetUser, AuthState } from "../../types";

export const setUser = (
  user: Exclude<AuthState["user"], undefined>
): AuthActionSetUser => {
  return {
    type: "AUTH_SET_USER",
    payload: { user },
  };
};
