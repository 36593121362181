import { Divider, Form, Radio, Tooltip, Typography } from "antd";
import React from "react";
import { ReportConfig } from "../../types";
import { componentStyles } from "../../utils";

type BIStepExtrasProps = {
  value: ReportConfig["extras"];
  onChange: React.Dispatch<React.SetStateAction<BIStepExtrasProps["value"]>>;
};

const BIStepExtras: React.FC<BIStepExtrasProps> = ({ value, onChange }) => {
  return (
    <Form layout="horizontal" labelCol={{ span: 8 }} labelAlign="left">
      <Typography.Title level={4}>Outros problemas</Typography.Title>

      <Form.Item label='INCLUIR "OUTROS PROBLEMAS"'>
        <Radio.Group
          value={value.includeOthers}
          onChange={(e) =>
            onChange((currExtrasVal) => ({
              ...currExtrasVal,
              includeOthers: e.target.value,
            }))
          }
          style={styles.itemsContainer}
        >
          <Tooltip
            placement="topLeft"
            title="Incluir apenas os problemas comuns (pré-relacionados)"
          >
            <Radio.Button value={false} style={styles.item}>
              Não
            </Radio.Button>
          </Tooltip>
          <Tooltip
            placement="topLeft"
            title="Incluir todos os problemas – comuns ou não"
          >
            <Radio.Button value={true} style={styles.item}>
              Sim
            </Radio.Button>
          </Tooltip>
        </Radio.Group>
      </Form.Item>

      {value.includeOthers && (
        <Form.Item
          label='EXPANDIR "OUTROS PROBLEMAS"'
          tooltip='Relacionar cada um dos "Outros problemas" em sua própria linha?'
        >
          <Radio.Group
            value={value.expandOthers}
            onChange={(e) =>
              onChange((currExtrasVal) => ({
                ...currExtrasVal,
                expandOthers: e.target.value,
              }))
            }
            style={styles.itemsContainer}
          >
            <Tooltip
              placement="topLeft"
              title='Aglutinar os problemas incomuns em um só item "Outros"'
            >
              <Radio.Button value={false} style={styles.item}>
                Não
              </Radio.Button>
            </Tooltip>
            <Tooltip
              placement="topLeft"
              title="Relacionar cada um dos problemas incomuns em sua própria linha"
            >
              <Radio.Button value={true} style={styles.item}>
                Sim
              </Radio.Button>
            </Tooltip>
          </Radio.Group>
        </Form.Item>
      )}

      <Divider />

      <Typography.Title level={4}>Filtrar por interrupções</Typography.Title>

      {Object.entries(value.filterByInterruptions).map(
        ([filterKey, filterValue]) => {
          let translatedKey: string;
          switch (filterKey) {
            case "equipment":
              translatedKey = "Máquina";
              break;
            case "line":
              translatedKey = "Linha";
              break;
            case "production":
              translatedKey = "Produção";
              break;
            default:
              translatedKey = filterKey;
          }

          return (
            <Form.Item label={`DE ${translatedKey.toUpperCase()}`}>
              <Radio.Group
                value={filterValue}
                onChange={(e) =>
                  onChange((currExtrasVal) => ({
                    ...currExtrasVal,
                    filterByInterruptions: {
                      ...currExtrasVal.filterByInterruptions,
                      [filterKey]: e.target.value,
                    },
                  }))
                }
                style={styles.itemsContainer}
              >
                <Tooltip
                  placement="topLeft"
                  title={`Relacionar apenas os problemas que NÃO interromperam a ${translatedKey.toLowerCase()}`}
                >
                  <Radio.Button value="didNotStopOnly" style={styles.item}>
                    Não parou
                  </Radio.Button>
                </Tooltip>
                <Tooltip
                  placement="topLeft"
                  title={`Relacionar apenas os problemas que – SIM – interromperam a ${translatedKey.toLowerCase()}`}
                >
                  <Radio.Button value="didStopOnly" style={styles.item}>
                    Parou
                  </Radio.Button>
                </Tooltip>
                <Tooltip
                  placement="topLeft"
                  title={`Relacionar todos os problemas – que interromperam ou não a ${translatedKey.toLowerCase()}`}
                >
                  <Radio.Button value="both" style={styles.item}>
                    Ambos
                  </Radio.Button>
                </Tooltip>
              </Radio.Group>
            </Form.Item>
          );
        }
      )}

      <Divider />
    </Form>
  );
};

const styles = componentStyles({
  itemsContainer: {
    display: "flex",
    width: "100%",
  },
  item: {
    flexGrow: 1,
  },
});

export default BIStepExtras;
