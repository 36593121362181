import { HomeOutlined } from "@ant-design/icons";
import { Affix, Button, PageHeader, Tooltip } from "antd";
import { ComponentProps } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import CONSTANTS from "../constants";
import { componentStyles } from "../utils";

type AppHeaderProps = {
  buttons?: {
    label: string;
    route: string;
    type?: ComponentProps<typeof Button>["type"];
  }[];
};

const AppHeader: React.FC<AppHeaderProps> = ({ buttons }) => {
  const location = useLocation();
  const history = useHistory();

  return (
    <Affix>
      <PageHeader
        title="Nova A3 • Manutenção"
        style={styles.pageHeader}
        extra={buttons?.map((b) => (
          <Link to={b.route} key={b.route}>
            <Button type={b.type} disabled={location.pathname === b.route}>
              {b.label}
            </Button>
          </Link>
        ))}
        backIcon={
          <Tooltip placement="rightTop" title="Dashboard">
            <HomeOutlined />
          </Tooltip>
        }
        onBack={location.pathname !== "/" ? () => history.push("/") : undefined}
      />
    </Affix>
  );
};

const styles = componentStyles({
  pageHeader: {
    borderBottom: "1px solid #f0f0f0",
    paddingLeft: CONSTANTS.STYLE.SPACING.MD,
    paddingRight: CONSTANTS.STYLE.SPACING.MD,
    backgroundColor: "white",
  },
});

export default AppHeader;
