import { SyncOutlined, UserOutlined } from "@ant-design/icons";
import {
  Badge,
  Button,
  Card,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Select,
  Tag,
  Typography,
} from "antd";
import moment from "moment-timezone";
import React, { useCallback, useState } from "react";
import {
  IoCheckmarkDoneOutline,
  IoCreateOutline,
  IoLockClosedOutline,
  IoThumbsUpOutline,
} from "react-icons/io5";
import CONSTANTS from "../../constants";
import { FsTicket } from "../../types";
import {
  componentStyles,
  confirmTicket,
  getDepartmentById,
  getTicketColor,
  shareTicketSolutionStatus,
  transmitTicketSolution,
} from "../../utils";
import Icon from "../Icon";

type TicketCardProps = {
  ticket: FsTicket;
  style?: React.CSSProperties;
};

const TicketCard: React.FC<TicketCardProps> = ({ ticket, style }) => {
  const {
    id,
    status,
    dpt,
    username,
    description,
    createdAt,
    acceptedAt,
    solvedAt,
    closedAt,
    machine,
    priority,
  } = ticket;

  const [showModal, setShowModal] = useState(false);
  const [modalIsLoading, setModalIsLoading] = useState(false);
  const [modalFormMode, setModalFormMode] = useState<
    "shareStatus" | "transmitSolution"
  >("shareStatus");

  const [updatedPriority, setUpdatedPriority] =
    useState<Exclude<FsTicket["priority"], undefined>>("low");
  const [solutionStatus, setSolutionStatus] = useState("");
  const [solution, setSolution] = useState("");
  const [updatedAssignedMaintainer, setUpdatedAssignedMaintainer] =
    useState("");

  const [modalErrors, setModalErrors] = useState<{
    assignedMaintainer?: string;
    solutionStatus?: string;
    solution?: string;
  }>({});

  const handleClick = useCallback(() => {
    setShowModal(true);
  }, []);

  const handleFormSubmit = useCallback(async () => {
    setModalIsLoading(true);

    switch (status) {
      case "pending":
        if (!updatedAssignedMaintainer.trim()) {
          return setModalErrors((curr) => ({
            ...curr,
            assignedMaintainer: "Defina um manutentor responsável para a OS",
          }));
        }

        const { error } = await confirmTicket(ticket, {
          priority: updatedPriority,
          assignedMaintainer: updatedAssignedMaintainer.trim(),
        });

        if (error) {
          return notification.error({
            message: "Erro ao aceitar a OS",
            description:
              "Um erro inesperado ocorreu. Por favor, entre em contato com o administrador do aplicativo para mais informações.",
          });
        } else {
          return notification.success({
            message: "OS aceita",
            description: `OS nº ${ticket.id} aceita com sucesso!`,
          });
        }
      case "solving":
        if (modalFormMode === "shareStatus") {
          if (!solutionStatus.trim()) {
            return setModalErrors((curr) => ({
              ...curr,
              solutionStatus: "Campo obrigatório",
            }));
          }
          if (!updatedAssignedMaintainer.trim()) {
            return setModalErrors((curr) => ({
              ...curr,
              assignedMaintainer: "Campo obrigatório",
            }));
          }

          const { error } = await shareTicketSolutionStatus(ticket, {
            solutionStatus,
            assignedMaintainer: updatedAssignedMaintainer.trim(),
          });

          if (error) {
            return notification.error({
              message: "Erro ao compartilhar status da solução",
              description:
                "Um erro inesperado ocorreu. Por favor, entre em contato com o administrador do aplicativo para mais informações.",
            });
          } else {
            return notification.success({
              message: "Status da solução compartilhado",
              description: `OS nº ${ticket.id} – Status da solução compartilhado com sucesso!`,
            });
          }
        } else if (modalFormMode === "transmitSolution") {
          if (!solution.trim()) {
            return setModalErrors((curr) => ({
              ...curr,
              solution: "Campo obrigatório",
            }));
          }
          if (!updatedAssignedMaintainer.trim()) {
            return setModalErrors((curr) => ({
              ...curr,
              assignedMaintainer: "Campo obrigatório",
            }));
          }

          const { error } = await transmitTicketSolution(ticket, {
            solution,
            assignedMaintainer: updatedAssignedMaintainer.trim(),
          });

          if (error) {
            return notification.error({
              message: "Erro ao solucionar OS",
              description:
                "Um erro inesperado ocorreu. Por favor, entre em contato com o administrador do aplicativo para mais informações.",
            });
          } else {
            return notification.success({
              message: "OS solucionada",
              description: `OS nº ${ticket.id} solucionada com sucesso!`,
            });
          }
        }
    }

    setModalIsLoading(false);
  }, [
    ticket,
    status,
    updatedPriority,
    solutionStatus,
    solution,
    updatedAssignedMaintainer,
    modalFormMode,
  ]);

  const TicketStep: React.FC<{
    icon: React.ReactNode;
    timestamp?: string | null;
  }> = ({ icon, timestamp }) => {
    return (
      <div style={styles.ticketStep}>
        <Icon style={styles.ticketStepIcon}>{icon}</Icon>
        <span style={styles.ticketStepText}>
          {timestamp
            ? moment(timestamp).tz("America/Sao_Paulo").format("DD/MM")
            : "–––"}
        </span>
      </div>
    );
  };

  return (
    <>
      <Card
        hoverable={["pending", "solving"].includes(status)}
        style={{ ...styles.card, ...style }}
        bodyStyle={styles.cardBody}
        onClick={handleClick}
      >
        <div style={styles.header}>
          <div>
            <Tag color={getTicketColor(username)}>
              <small
                style={{
                  color: getDepartmentById(username)?.style.colors.text,
                }}
              >
                {dpt}
              </small>
            </Tag>
            <Tag color="#2db7f5">
              <small>
                <i>{machine}</i>
              </small>
            </Tag>
          </div>
          {priority && status === "solving" && (
            <Tag
              color={
                priority === "low"
                  ? "success"
                  : priority === "medium"
                  ? "warning"
                  : "error"
              }
              style={styles.priorityTag}
            >
              <small>
                <strong>
                  {priority === "high"
                    ? "ALTA"
                    : priority === "medium"
                    ? "MÉDIA"
                    : "BAIXA"}
                </strong>
              </small>
            </Tag>
          )}
        </div>

        <small style={styles.ticketId}>
          <Typography.Text italic type="secondary">
            #{id}
          </Typography.Text>
        </small>

        <Typography.Title level={5} style={styles.cardTitle}>
          {description}
        </Typography.Title>

        <Divider style={styles.divider} />

        <div style={styles.ticketStepsContainer}>
          <TicketStep icon={<IoCreateOutline />} timestamp={createdAt} />
          <TicketStep icon={<IoThumbsUpOutline />} timestamp={acceptedAt} />
          <TicketStep icon={<IoCheckmarkDoneOutline />} timestamp={solvedAt} />
          <TicketStep icon={<IoLockClosedOutline />} timestamp={closedAt} />
        </div>
      </Card>

      <Modal
        visible={["pending", "solving"].includes(status) && showModal}
        title={
          status === "pending"
            ? "Confirmar OS"
            : status === "solving"
            ? modalFormMode === "shareStatus"
              ? "Compartilhar Status da Solução"
              : "Transmitir Solução"
            : undefined
        }
        onOk={handleFormSubmit}
        onCancel={() => setShowModal(false)}
        confirmLoading={modalIsLoading}
        okText={
          status === "pending"
            ? "Confirmar OS"
            : status === "solving"
            ? modalFormMode === "shareStatus"
              ? "Compartilhar Status"
              : "Transmitir Solução"
            : undefined
        }
      >
        {status === "pending" ? (
          <>
            <Form layout="vertical">
              <Form.Item label="Prioridade" required>
                <Select
                  defaultValue="low"
                  onChange={setUpdatedPriority}
                  autoFocus
                >
                  <Select.Option value="low">
                    <Badge status="success" text="Baixa" />
                  </Select.Option>
                  <Select.Option value="medium">
                    <Badge status="warning" text="Média" />
                  </Select.Option>
                  <Select.Option value="high">
                    <Badge status="error" text="Alta" />
                  </Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Responsável"
                hasFeedback
                required
                validateStatus={modalErrors?.assignedMaintainer && "error"}
                help={modalErrors?.assignedMaintainer}
              >
                <Input
                  placeholder="Manutentor responsável"
                  prefix={<UserOutlined />}
                  value={updatedAssignedMaintainer}
                  onChange={(ev) =>
                    setUpdatedAssignedMaintainer(ev.target.value)
                  }
                />
              </Form.Item>
            </Form>
          </>
        ) : status === "solving" ? (
          <>
            <Button
              block
              icon={<SyncOutlined />}
              onClick={() =>
                setModalFormMode((curr) =>
                  curr === "shareStatus" ? "transmitSolution" : "shareStatus"
                )
              }
            >
              Alternar para{" "}
              {modalFormMode === "shareStatus"
                ? "Transmitir Solução"
                : "Compartilhar Status"}
            </Button>

            <Divider />

            <Form layout="vertical">
              <Form.Item
                label={modalFormMode === "shareStatus" ? "Status" : "Solução"}
                hasFeedback
                required
                validateStatus={
                  modalFormMode === "shareStatus"
                    ? modalErrors?.solutionStatus && "error"
                    : modalErrors?.solution && "error"
                }
                help={
                  modalFormMode === "shareStatus"
                    ? modalErrors?.solutionStatus
                    : modalErrors?.solution
                }
              >
                <Input.TextArea
                  placeholder={
                    modalFormMode === "shareStatus"
                      ? "Status da solução"
                      : "Solução"
                  }
                  value={
                    modalFormMode === "shareStatus" ? solutionStatus : solution
                  }
                  onChange={({ target: { value } }) =>
                    modalFormMode === "shareStatus"
                      ? setSolutionStatus(value)
                      : setSolution(value)
                  }
                  rows={3}
                />
              </Form.Item>

              <Form.Item
                label="Responsável"
                hasFeedback
                required
                validateStatus={modalErrors?.assignedMaintainer && "error"}
                help={modalErrors?.assignedMaintainer}
              >
                <Input
                  placeholder="Manutentor responsável"
                  prefix={<UserOutlined />}
                  value={updatedAssignedMaintainer}
                  onChange={(ev) =>
                    setUpdatedAssignedMaintainer(ev.target.value)
                  }
                />
              </Form.Item>
            </Form>
          </>
        ) : (
          <></>
        )}
      </Modal>
    </>
  );
};

const styles = componentStyles({
  card: {
    borderRadius: CONSTANTS.STYLE.BORDER_RADIUS.SM,
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: CONSTANTS.STYLE.SPACING.XS,
  },
  priorityTag: {
    marginRight: 0,
  },
  cardBody: {
    paddingTop: CONSTANTS.STYLE.SPACING.SM,
    paddingBottom: CONSTANTS.STYLE.SPACING.MD,
  },
  ticketId: {
    marginRight: CONSTANTS.STYLE.SPACING.XS + 2,
  },
  cardTitle: {
    display: "inline",
    margin: 0,
  },
  divider: {
    marginTop: CONSTANTS.STYLE.SPACING.XS,
    marginBottom: CONSTANTS.STYLE.SPACING.SM + 3,
  },
  ticketStepsContainer: {
    display: "flex",
  },
  ticketStep: {
    display: "inline-flex",
    alignItems: "flex-start",
    width: "25%",
  },
  ticketStepIcon: {
    marginRight: CONSTANTS.STYLE.SPACING.XS,
  },
  ticketStepText: {
    fontSize: 10,
  },
});

export default TicketCard;
