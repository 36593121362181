import {
  Button,
  Col,
  Collapse,
  Divider,
  message,
  Result,
  Row,
  Typography,
} from "antd";
import firebase from "firebase";
import moment from "moment";
import QRCodeStyling from "qr-code-styling";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Report } from "../classes";
import {
  LoadingIndicator,
  PageContainer,
  ReportCard,
  TicketCard,
} from "../components";
import CONSTANTS from "../constants";
import { FsBI } from "../types";
import { componentStyles } from "../utils";

type BIDetailsPageProps = {
  id: string;
};

const BIDetailsPage: React.FC<BIDetailsPageProps> = ({ id }) => {
  const [loading, setLoading] = useState(true);
  const [report, setReport] = useState<FsBI | null>();
  const [generating, setGenerating] = useState({ pdf: false, csv: false });
  const history = useHistory();

  const getReport = useCallback(async () => {
    const snapshot = await firebase
      .firestore()
      .collection("manut-bi")
      .where("internalId", "==", parseInt(id))
      .get();

    if (snapshot.empty) {
      setReport(null);
    } else {
      setReport(snapshot.docs[0].data() as FsBI);
    }

    setLoading(false);
  }, [id]);

  const downloadReport = async (format: "csv" | "pdf") => {
    setGenerating({ ...generating, [format]: true });
    message.loading({
      content: "Preparando...",
      key: `loading-${id}`,
    });

    const reportConfig = report!;

    const pdfQrCode = await new QRCodeStyling({
      data: reportConfig.qrCode.data,
      backgroundOptions: {
        color: "#003C68",
      },
      dotsOptions: {
        color: "#D8EEF9",
      },
    }).getRawData();

    try {
      const generatedReport = new Report(
        reportConfig.internalId,
        reportConfig.tickets,
        {
          ...reportConfig,
          qrCodeURI: URL.createObjectURL(pdfQrCode),
          dateRange: [
            moment(report!.dateRange[0].toDate()),
            moment(report!.dateRange[1].toDate()),
          ],
        }
      );

      generatedReport[format]();

      message.success(`Download concluído`);
    } catch (e) {
      console.error(e);
      message.error(`Algo deu errado`);
    } finally {
      message.destroy(`loading-${id}`);
      setGenerating({ ...generating, [format]: false });
    }
  };

  const handleSeeReports = () => {
    history.push("/relatorios");
  };

  useEffect(() => {
    getReport();
  }, [getReport]);

  return (
    <PageContainer
      title={`BI #${id}`}
      style={{ padding: 0 }}
      titleContainerStyle={styles.titleContainer}
    >
      {loading ? (
        <div style={styles.loading}>
          <LoadingIndicator text="Buscando relatório..." />
        </div>
      ) : report ? (
        <div>
          <div>
            <Collapse
              bordered={false}
              defaultActiveKey={[]}
              style={{ paddingLeft: 4, paddingRight: 4 }}
            >
              <Collapse.Panel header="Informações do relatório" key="1">
                <div style={{ marginLeft: -20, marginRight: -20 }}>
                  <ReportCard hideButtons data={report} />
                </div>
              </Collapse.Panel>
            </Collapse>
          </div>

          <Row gutter={12} style={styles.content}>
            <Col xs={24} md={12}>
              <Button
                block
                type="primary"
                onClick={() => downloadReport("pdf")}
                style={styles.downloadBtn}
                loading={generating.pdf}
              >
                {generating.pdf ? "Preparando..." : "Baixar como PDF"}
              </Button>
            </Col>
            <Col xs={24} md={12}>
              <Button
                block
                onClick={() => downloadReport("csv")}
                style={styles.downloadBtn}
                loading={generating.csv}
              >
                {generating.csv ? "Preparando..." : "Baixar como CSV"}
              </Button>
            </Col>
          </Row>

          <div style={styles.content}>
            <Row>
              <Col
                xs={24}
                md={0}
                style={{ marginTop: CONSTANTS.STYLE.SPACING.SM }}
              >
                <Divider orientation="left">
                  <Typography.Text>OS no relatório</Typography.Text>
                </Divider>
              </Col>
              <Col
                xs={0}
                md={24}
                style={{ marginTop: CONSTANTS.STYLE.SPACING.MD }}
              >
                <Divider orientation="left">
                  <Typography.Title level={3}>OS no relatório</Typography.Title>
                </Divider>
              </Col>
            </Row>
          </div>

          <Row gutter={12} style={styles.ticketCardsContainer}>
            {report.tickets.map((ticket) => (
              <Col
                key={ticket.id}
                xs={24}
                lg={12}
                xl={8}
                style={{ marginBottom: CONSTANTS.STYLE.SPACING.SM }}
              >
                <TicketCard ticket={ticket} />
              </Col>
            ))}
          </Row>
        </div>
      ) : (
        <div style={styles.notFound}>
          <Result
            status="404"
            title="Relatório não encontrado"
            subTitle={`Não foi possível encontrar o relatório #${id}`}
            extra={
              <Button type="primary" onClick={handleSeeReports}>
                Voltar
              </Button>
            }
          />
        </div>
      )}
    </PageContainer>
  );
};

const styles = componentStyles({
  titleContainer: {
    borderBottom: "1px solid #f0f0f0",
    padding: CONSTANTS.STYLE.SPACING.MD,
    paddingBottom: CONSTANTS.STYLE.SPACING.MD,
    marginBottom: 0,
  },
  loading: {
    marginTop: CONSTANTS.STYLE.SPACING.MD * 2,
  },
  notFound: {
    height: "calc(100vh - 192px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    paddingLeft: CONSTANTS.STYLE.SPACING.MD,
    paddingRight: CONSTANTS.STYLE.SPACING.MD,
  },
  downloadBtn: {
    marginTop: CONSTANTS.STYLE.SPACING.SM,
  },
  ticketCardsContainer: {
    height: "calc(100vh - 376px)",
    overflow: "scroll",
    paddingBottom: CONSTANTS.STYLE.SPACING.MD,
    paddingLeft: CONSTANTS.STYLE.SPACING.MD,
    paddingRight: CONSTANTS.STYLE.SPACING.MD,
  },
});

export default BIDetailsPage;
