import store from "../../store";
import { FsTicket } from "../../types";

export const isTicket = (ticket: unknown): ticket is FsTicket => {
  return (
    typeof ticket === "object" &&
    ticket !== null &&
    !Array.isArray(ticket) &&
    "id" in ticket
  );
};

export const filterTicketsByStatus = (
  tickets: FsTicket[]
): Record<FsTicket["status"], FsTicket[]> => {
  const filtered = (tickets: FsTicket[], targetStatus: FsTicket["status"]) => {
    return tickets.filter((t) => t.status === targetStatus);
  };

  return {
    pending: filtered(tickets, "pending"),
    solving: filtered(tickets, "solving"),
    solved: filtered(tickets, "solved"),
    closed: filtered(tickets, "closed"),
    refused: filtered(tickets, "refused"),
  };
};

export const problemIsOthers = (
  ticketOrDptProblem: FsTicket | { dpt: string; problem: string }
) => {
  let dptId: string;
  let problem: string;
  if (isTicket(ticketOrDptProblem)) {
    dptId = ticketOrDptProblem.username;
    problem = ticketOrDptProblem.description;
  } else {
    dptId = ticketOrDptProblem.dpt;
    problem = ticketOrDptProblem.problem;
  }

  const dptProblems = store
    .getState()
    .data.departments.find((dpt) => dpt.id === dptId)?.manutIssues;

  return dptProblems ? !dptProblems.includes(problem) : false;
};
