import { Button } from "antd";
import React, { ComponentProps } from "react";
import { IoArrowForwardOutline } from "react-icons/io5";
import CONSTANTS from "../../constants";
import { componentStyles } from "../../utils";

type BIStepContainerProps = {
  onNext: () => void;
  nextBtnDisabled?: boolean;
  nextBtnText?: string;
  nextBtnSize?: ComponentProps<typeof Button>["size"];
};

const BIStepContainer: React.FC<BIStepContainerProps> = ({
  onNext,
  nextBtnDisabled,
  nextBtnText,
  nextBtnSize,
  children,
}) => {
  return (
    <div style={styles.container}>
      {children}

      <div style={styles.footer}>
        <Button
          block
          type="primary"
          icon={<IoArrowForwardOutline style={styles.footerBtnIcon} />}
          onClick={onNext}
          style={styles.footerBtn}
          disabled={nextBtnDisabled}
          size={nextBtnSize}
        >
          {nextBtnText || "Próximo"}
        </Button>
      </div>
    </div>
  );
};

const styles = componentStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    overflow: "scroll",
    paddingTop: CONSTANTS.STYLE.SPACING.MD,
    marginBottom: 73 + 73 - CONSTANTS.STYLE.SPACING.MD,
    marginRight: -CONSTANTS.STYLE.SPACING.MD,
    marginLeft: -CONSTANTS.STYLE.SPACING.MD,
    paddingRight: CONSTANTS.STYLE.SPACING.MD,
    paddingLeft: CONSTANTS.STYLE.SPACING.MD,
    paddingBottom: CONSTANTS.STYLE.SPACING.MD,
    height: "calc(100% - 73px)",
  },
  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    backgroundColor: "white",
    borderTop: "1px solid rgba(0, 0, 0, 0.06)",
    padding: CONSTANTS.STYLE.SPACING.MD,
    zIndex: 2,
  },
  footerBtn: {
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
    justifyContent: "center",
  },
  footerBtnIcon: {
    marginLeft: CONSTANTS.STYLE.SPACING.SM,
  },
});

export default BIStepContainer;
