import { useSelector } from "react-redux";
import { AuthState, RootState } from "../types";

const useAuth = () => {
  const auth = useSelector<RootState, AuthState>((state) => state.auth);

  return auth;
};

export default useAuth;
