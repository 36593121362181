import { detect as detectBrowser } from "detect-browser";
import firebase from "firebase";
import moment from "moment-timezone";
import uniqid from "uniqid";
import { FsTicket } from "../../types";
import { getPushTokens, sendNotification } from "../notifications";
import { translatePriority } from "../translators";

export async function confirmTicket(
  ticket: FsTicket,
  data: {
    priority: Exclude<FsTicket["priority"], undefined>;
    assignedMaintainer: string;
  }
): Promise<{ error: { title: string; description: string } | null }> {
  if (!data.assignedMaintainer.trim()) {
    return {
      error: {
        title: "Campo requerido",
        description: 'O campo "Manutentor(es)" é obrigatório.',
      },
    };
  }

  try {
    await firebase
      .firestore()
      .collection("tickets")
      .doc(ticket.id)
      .update({
        acceptedAt: moment().toISOString(),
        status: "solving",
        priority: data.priority,
        assignedMaintainer: data.assignedMaintainer
          ? data.assignedMaintainer
          : null,
      });

    await pushTicketEvents(ticket.id, {
      type: "ticketConfirmed",
      payload: {
        priority: data.priority,
        assignedMaintainer: data.assignedMaintainer,
      },
    });

    sendNotification({
      to: await getPushTokens(ticket.username),
      title: `OS #${ticket.id}`,
      body: `A OS está sendo resolvida\nPrioridade: ${translatePriority(
        data.priority
      )}; Responsável: ${data.assignedMaintainer}`,
    });

    /* TO DO -- Send to viewOnly users
    
    sendNotification({
      to: await getPushTokens(
        getDepartments()
          .filter((d) => d.isViewOnly())
          .map((d) => d.username)
      ),
      title: `OS #${ticket.id} (${ticket.dpt})`,
      body: `Confirmada pela Manutenção\nPrioridade: ${translatePriority(
        data.priority
      )}; Responsável: ${data.assignedMaintainer}`,
    });
    */

    return { error: null };
  } catch (e) {
    return {
      error: {
        title: "Erro ao aceitar a OS",
        description:
          "Um erro inesperado ocorreu. Por favor, entre em contato com o administrador do aplicativo para mais informações.",
      },
    };
  }
}

export async function shareTicketSolutionStatus(
  ticket: FsTicket,
  data: { solutionStatus: string; assignedMaintainer: string }
): Promise<{ error: { title: string; description: string } | null }> {
  if (!data.solutionStatus.trim()) {
    return {
      error: {
        title: "Campo requerido",
        description: 'O campo "Status da solução" é obrigatório.',
      },
    };
  } else if (!data.assignedMaintainer.trim()) {
    return {
      error: {
        title: "Campo requerido",
        description: 'O campo "Responsável" é obrigatório.',
      },
    };
  }

  data.solutionStatus = data.solutionStatus.trim();
  data.assignedMaintainer = data.assignedMaintainer.trim();

  try {
    await firebase
      .firestore()
      .collection("tickets")
      .doc(ticket.id)
      .update({
        solutionSteps: firebase.firestore.FieldValue.arrayUnion(
          data.solutionStatus
        ),
      });

    await pushTicketEvents(ticket.id, {
      type: "solutionStepAdded",
      payload: {
        solutionStep: {
          type: "step",
          content: data.solutionStatus,
          who: data.assignedMaintainer,
        },
      },
    });

    sendNotification({
      to: await getPushTokens(ticket.username),
      title: `OS #${ticket.id}`,
      body: `Novo progresso na solução da OS: "${data.solutionStatus}" (${data.assignedMaintainer})`,
    });

    /* TO DO -- Send to viewOnly users
    
    sendNotification({
      to: await this.getPushTokens(
        Db.getDepartments()
          .filter((d) => d.isViewOnly())
          .map((d) => d.username)
      ),
      title: `OS #${ticket.id} (${ticket.dpt})`,
      body: `Novo progresso na solução da OS: "${data.solutionStatus}" (${data.assignedMaintainer})`,
    });
    */

    return { error: null };
  } catch (e) {
    return {
      error: {
        title: "Erro ao compartilhar status",
        description:
          "Um erro inesperado ocorreu. Por favor, entre em contato com o administrador do aplicativo para mais informações.",
      },
    };
  }
}

export async function transmitTicketSolution(
  ticket: FsTicket,
  data: {
    solution: Exclude<FsTicket["solution"], undefined | null>;
    assignedMaintainer: string;
  }
): Promise<{ error: { title: string; description: string } | null }> {
  if (!data.solution.trim()) {
    return {
      error: {
        title: "Campo requerido",
        description: 'O campo "Descrição da solução" é obrigatório.',
      },
    };
  } else if (!data.assignedMaintainer.trim()) {
    return {
      error: {
        title: "Campo requerido",
        description: 'O campo "Responsável" é obrigatório.',
      },
    };
  }

  data.solution = data.solution.trim();
  data.assignedMaintainer = data.assignedMaintainer.trim();

  try {
    await firebase.firestore().collection("tickets").doc(ticket.id).update({
      solvedAt: moment().toISOString(),
      status: "solved",
      solution: data.solution.trim(),
    });

    await pushTicketEvents(ticket.id, [
      {
        type: "solutionTransmitted",
        payload: {
          solution: data.assignedMaintainer
            ? { content: data.solution, who: data.assignedMaintainer }
            : data.solution,
        },
      },
      {
        type: "solutionStepAdded",
        payload: {
          solutionStep: {
            type: "solutionTransmitted",
            content: data.solution,
            who: data.assignedMaintainer,
          },
        },
      },
    ]);

    sendNotification({
      to: await getPushTokens(ticket.username),
      title: `[AÇÃO NECESSÁRIA] OS #${ticket.id} solucionada`,
      body: `Acesse o app para aceitar a solução: "${data.solution}"`,
    });

    /* TO DO -- Send to viewOnly users
    sendNotification({
      to: await this.getPushTokens(
        Db.getDepartments()
          .filter((d) => d.isViewOnly())
          .map((d) => d.username)
      ),
      title: `OS #${ticket.id} (${ticket.dpt})`,
      body: `Nova solução disponível: "${data.solution}" (${data.assignedMaintainer})`,
    });
    */

    return { error: null };
  } catch (e) {
    return {
      error: {
        title: "Erro ao solucionar a OS",
        description:
          "Um erro inesperado ocorreu. Por favor, entre em contato com o administrador do aplicativo para mais informações.",
      },
    };
  }
}

async function pushTicketEvents(
  ticketId: FsTicket["id"],
  events:
    | {
        type: FsTicket["events"][0]["type"];
        payload?: FsTicket["events"][0]["payload"];
      }
    | {
        type: FsTicket["events"][0]["type"];
        payload?: FsTicket["events"][0]["payload"];
      }[]
) {
  if (!Array.isArray(events)) {
    events = [events];
  }

  const newEvents = events.map((e) => buildTicketEvent(e.type, e.payload));
  const registeredEvents = (
    await firebase.firestore().collection("tickets").doc(ticketId).get()
  ).data()!.events as FsTicket["events"] | undefined | null;

  await firebase
    .firestore()
    .collection("tickets")
    .doc(ticketId)
    .update({
      events: registeredEvents
        ? [...registeredEvents, ...newEvents]
        : newEvents,
    });
}

function buildTicketEvent<T extends FsTicket["events"][0]["type"]>(
  type: T,
  payload?: FsTicket["events"][0]["payload"]
): FsTicket["events"][0] {
  const browser = detectBrowser();

  return {
    id: uniqid(),
    type,
    timestamp: moment().toISOString(),
    device: {
      name: "Web Dashboard",
      model: "Web",
      os: { name: browser?.name || "Web", version: browser?.version || "—" },
    },
    payload: payload ? payload : null,
  };
}
