import { Typography } from "antd";
import { CSSProperties, ReactNode } from "react";
import CONSTANTS from "../constants";
import { componentStyles } from "../utils";

type PageContainerProps = {
  title: string;
  subtitle?: ReactNode;
  style?: CSSProperties;
  titleContainerStyle?: CSSProperties;
  children: ReactNode;
};

const PageContainer: React.FC<PageContainerProps> = ({
  title,
  subtitle,
  style,
  titleContainerStyle,
  children,
}) => {
  return (
    <div style={{ ...styles.container, ...style }}>
      <div style={{ ...styles.titleContainer, ...titleContainerStyle }}>
        <Typography.Title style={styles.title}>{title}</Typography.Title>
        {subtitle}
      </div>
      {children}
    </div>
  );
};

const styles = componentStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "hidden",
    padding: CONSTANTS.STYLE.SPACING.MD,
    backgroundColor: "white",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: CONSTANTS.STYLE.SPACING.SM,
    marginBottom: CONSTANTS.STYLE.SPACING.MD,
    zIndex: 6,
    backgroundColor: "white",
  },
  title: {
    margin: 0,
    padding: 0,
  },
});

export default PageContainer;
