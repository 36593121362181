import { Divider, message, Popconfirm, Steps } from "antd";
import React, { ReactNode, useCallback, useState } from "react";
import CONSTANTS from "../../constants";
import { componentStyles } from "../../utils";
import BIStepContainer from "./BIStepContainer";

type BIStepsProps = {
  steps: {
    title: string;
    description: string;
    children: ReactNode;
  }[];
  current: number;
  setterFn: (step: number) => void;
  onReset: () => void;
  preventNextStep?: boolean;
};

const BISteps: React.FC<BIStepsProps> = ({
  steps,
  current,
  setterFn,
  onReset,
  preventNextStep,
}) => {
  const [showPopConfirm, setShowPopConfirm] = useState(false);

  const handleReset = useCallback(() => {
    setterFn(0);
    setShowPopConfirm(false);
    onReset();
  }, [setterFn, onReset]);

  const handleStepChange = useCallback(
    (step: number) => {
      setShowPopConfirm(false);
      if (step > current) {
        if (step - current === 1) {
          if (!preventNextStep) {
            return setterFn(step);
          } else {
            return message.error(
              "Revise as definições do passo atual primeiro"
            );
          }
        } else {
          return message.error(
            "Você precisa confirmar os passos anteriores primeiro"
          );
        }
      }
      if (step === 0 && current > 0) {
        return setShowPopConfirm(true);
      }
      setterFn(step);
    },
    [current, preventNextStep, setterFn]
  );

  return (
    <>
      <Popconfirm
        placement="topLeft"
        title="Tem certeza que deseja recomeçar?"
        onConfirm={() => setShowPopConfirm(false)}
        onCancel={handleReset}
        okText="Não"
        cancelText="Sim"
        visible={showPopConfirm}
      >
        <Steps size="small" current={current} onChange={handleStepChange}>
          {steps.map((step, idx) => (
            <Steps.Step
              key={step.title + "-" + idx}
              title={step.title}
              description={current > idx ? step.description : undefined}
            />
          ))}
        </Steps>
      </Popconfirm>

      <Divider style={styles.divider} />

      {current !== steps.length - 1 ? (
        <BIStepContainer
          onNext={() => handleStepChange(current + 1)}
          nextBtnDisabled={preventNextStep}
        >
          {steps[current].children}
        </BIStepContainer>
      ) : (
        steps[current].children
      )}
    </>
  );
};

const styles = componentStyles({
  divider: {
    marginLeft: -CONSTANTS.STYLE.SPACING.MD,
    width: "100vw",
    marginBottom: 0,
  },
});

export default BISteps;
