import {
  Button,
  Col,
  Divider,
  Empty,
  notification,
  Result,
  Row,
  Tabs,
  Typography,
} from "antd";
import firebase from "firebase";
import moment, { Moment } from "moment";
import { useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import {
  LoadingIndicator,
  PageContainer,
  ReportCard,
  TicketsReport,
} from "../components";
import CONSTANTS from "../constants";
import { FsBI } from "../types";
import { componentStyles, generateCSV } from "../utils";

const ReportsPage: React.FC = () => {
  const [reports, loading] = useCollectionData<FsBI, "id">(
    firebase.firestore().collection("manut-bi"),
    { idField: "id" }
  );
  const [loadingReport, setLoadingReport] = useState(false);

  const [ticketsReportDateRange, setTicketsReportDateRange] = useState<
    [Moment, Moment]
  >([moment().subtract(1, "day"), moment()]);

  const handleReportGeneration = async () => {
    setLoadingReport(true);
    const selectedTickets = await generateCSV({
      dateRange: [
        moment(ticketsReportDateRange[0]).subtract(1, "day").endOf("day"),
        moment(ticketsReportDateRange[1]).endOf("day"),
      ],
    });
    notification.success({
      message: "Seu relatório está pronto!",
      description: `${selectedTickets.length} OS relacionadas.`,
    });
    setLoadingReport(false);
  };

  return (
    <PageContainer title="Relatórios">
      <Divider style={styles.divider} />
      <Row style={styles.container}>
        <Col
          span={9}
          style={{ ...styles.container, ...styles.generateReportsContainer }}
        >
          <Typography.Title level={3} style={styles.generateReportsTitle}>
            Relatórios rápidos
          </Typography.Title>
          <Typography.Paragraph italic type="secondary">
            Emita relatórios internos de interesse da Manutenção
          </Typography.Paragraph>

          <Tabs
            defaultActiveKey="1"
            tabPosition="top"
            style={{ height: "100%" }}
          >
            {[
              {
                title: "OS no período",
                component: (
                  <TicketsReport
                    range={ticketsReportDateRange}
                    onChange={(values) => setTicketsReportDateRange(values)}
                  />
                ),
              },
            ].map((tabConfig, idx) => (
              <Tabs.TabPane
                tab={tabConfig.title}
                key={idx}
                style={{ height: "calc(100vh - 334px)" }}
              >
                <div
                  style={{
                    marginRight: CONSTANTS.STYLE.SPACING.MD,
                    height: "100%",
                  }}
                >
                  {tabConfig.component}
                  <Button
                    block
                    type="primary"
                    onClick={handleReportGeneration}
                    loading={loadingReport}
                  >
                    {loadingReport
                      ? "Preparando relatório..."
                      : "Gerar relatório"}
                  </Button>
                </div>
              </Tabs.TabPane>
            ))}
          </Tabs>
        </Col>

        <Col span={15} style={styles.container}>
          <div style={styles.content}>
            {loading ? (
              <LoadingIndicator text="Carregando..." />
            ) : reports ? (
              reports.length === 0 ? (
                <Empty description="Nenhum relatório encontrado" />
              ) : (
                <div style={styles.reportsContainer}>
                  <Typography.Title level={3} style={{ marginLeft: 20 }}>
                    Histórico de BIs
                  </Typography.Title>

                  {[...reports]
                    .sort((rA, rB) =>
                      moment(rB.issuedAt.toDate()).diff(
                        moment(rA.issuedAt.toDate())
                      )
                    )
                    .map((report) => (
                      <ReportCard data={report} key={report.id} />
                    ))}
                </div>
              )
            ) : (
              <Result
                status="500"
                title="Algo deu errado"
                subTitle="Não foi possível carregar seus relatórios no momento."
                extra={[
                  <Button type="primary" key="try-again">
                    Tentar novamente
                  </Button>,
                ]}
              />
            )}
          </div>
        </Col>
      </Row>
    </PageContainer>
  );
};

const styles = componentStyles({
  container: {
    height: "100%",
    marginBottom: -CONSTANTS.STYLE.SPACING.MD,
  },
  generateReportsContainer: {
    backgroundColor: "white",
    marginLeft: -CONSTANTS.STYLE.SPACING.MD,
    paddingLeft: CONSTANTS.STYLE.SPACING.MD,
    boxShadow:
      "0 10px 15px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.05)",
  },
  generateReportsTitle: {
    paddingTop: CONSTANTS.STYLE.SPACING.MD,
  },
  content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    marginBottom: -CONSTANTS.STYLE.SPACING.MD,
    marginRight: -CONSTANTS.STYLE.SPACING.MD,
    overflow: "hidden",
  },
  reportsContainer: {
    height: "100%",
    width: "100%",
    paddingTop: CONSTANTS.STYLE.SPACING.MD,
    overflow: "scroll",
    paddingBottom: CONSTANTS.STYLE.SPACING.MD * 5,
    marginRight: -CONSTANTS.STYLE.SPACING.MD,
  },
  divider: {
    marginLeft: -CONSTANTS.STYLE.SPACING.MD,
    width: "100vw",
    marginBottom: 0,
  },
});

export default ReportsPage;
