import firebase from "firebase";
import moment from "moment";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { FsTicket } from "../types";

const useTickets = (): [
  FsTicket[],
  boolean,
  firebase.FirebaseError | undefined
] => {
  const [tickets, loading, error] = useCollectionData<FsTicket, "id", "ref">(
    firebase.firestore().collection("tickets"),
    { idField: "id", refField: "ref" }
  );

  tickets?.sort((tA, tB) => moment(tA.createdAt).diff(moment(tB.createdAt)));

  return [tickets ? tickets : [], loading, error];
};

export default useTickets;
