import { CSSProperties } from "react";
import { getDepartments } from "./departments";

export const componentStyles = (styles: { [key: string]: CSSProperties }) =>
  styles;

export const getTicketColor = (ticketDpt: string) => {
  return getDepartments().find((dpt) => dpt.id === ticketDpt)?.style.colors
    .background;
};
