import { Badge, Typography } from "antd";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Report } from "../classes";
import {
  BIStepDatePicker,
  BIStepExtras,
  BIStepReport,
  BISteps,
  BIStepSelect,
  PageContainer
} from "../components";
import { useData, useQuery, useTickets } from "../hooks";
import { FsTicket, ReportConfig } from "../types";
import { getTicketShift, problemIsOthers } from "../utils";
import BIDetailsPage from "./BIDetails";

const extrasDefaultValue: ReportConfig["extras"] = {
  filterByInterruptions: {
    line: "both",
    equipment: "both",
    production: "both",
  },
  includeOthers: true,
  expandOthers: false,
};

const BIPage: React.FC = () => {
  const query = useQuery();
  const biId = query.get("id");

  const { departments, loading: isGlobalLoading } = useData();
  const [tickets] = useTickets();

  const [selectedDpts, setSelectedDpts] = useState<string[]>(
    departments.map((dpt) => dpt.id)
  );
  const [machines, setMachines] = useState<{ [key: string]: string[] }>({});
  const [shifts, setShifts] = useState<{ [key: string]: string[] }>({});
  const [problems, setProblems] = useState<{ [key: string]: string[] }>({});
  const [dateRange, setDateRange] = useState<[moment.Moment, moment.Moment]>([
    moment().startOf("month"),
    moment(),
  ]);
  const [extras, setExtras] = useState(extrasDefaultValue);
  const [currStep, setCurrStep] = useState(0);

  const [filteredTickets, setFilteredTickets] = useState<FsTicket[]>();

  const resetForm = useCallback(() => {
    setSelectedDpts(departments.map((dpt) => dpt.id));
  }, [departments]);

  const nextBtnDisabled = useCallback(() => {
    switch (currStep) {
      case 0:
        return selectedDpts.length === 0;
      case 1:
        return Object.values(machines).some(
          (machinesByDpt) => machinesByDpt.length === 0
        );
      case 2:
        return Object.values(problems)
          .filter((problemsByDpt) => problemsByDpt.length !== 0)
          .some((problemsByDpt) => problemsByDpt.length === 0);
      case 3:
        return Object.values(shifts).some(
          (shiftsByDpt) => shiftsByDpt.length === 0
        );
      default:
        return false;
    }
  }, [currStep, selectedDpts, machines, problems, shifts]);

  useEffect(() => {
    const updatedMachines: typeof machines = {};
    const updatedShifts: typeof shifts = {};
    const updatedProblems: typeof problems = {};
    selectedDpts.forEach((dptId) => {
      const dpt = departments.find((d) => d.id === dptId)!;
      updatedMachines[dptId] = Object.keys(dpt.machines).sort(
        (a, b) => dpt.machines[a].number - dpt.machines[b].number
      );
      updatedShifts[dptId] = ["A", "B", "C"];
      updatedProblems[dptId] = dpt.manutIssues ? dpt.manutIssues : [];
    });
    setMachines(updatedMachines);
    setShifts(updatedShifts);
    setProblems(updatedProblems);
  }, [selectedDpts, departments]);

  useEffect(() => {
    setSelectedDpts(departments.map((dpt) => dpt.id));
  }, [departments]);

  useEffect(() => {
    const updateTicketsCount = () => {
      const inclusiveDateRange = Report.makeDateRangeInclusive(dateRange);

      setFilteredTickets(
        tickets
          .filter((t) => selectedDpts?.includes(t.username))
          .filter((t) => machines[t.username]?.includes(t.machine))
          .filter((t) =>
            problemIsOthers(t)
              ? extras.includeOthers
              : problems[t.username]?.includes(t.description)
          )
          .filter((t) =>
            currStep >= 4
              ? moment(t.createdAt).isBetween(
                  inclusiveDateRange[0],
                  inclusiveDateRange[1]
                )
              : true
          )
          .filter((ticket) =>
            shifts[ticket.username].includes(getTicketShift(ticket))
          )
          .filter((t) => {
            return Object.entries(extras.filterByInterruptions).every(
              ([filterKey, filterValue]) =>
                filterValue === "didNotStopOnly"
                  ? !t.interruptions[
                      filterKey as keyof FsTicket["interruptions"]
                    ]
                  : filterValue === "didStopOnly"
                  ? t.interruptions[
                      filterKey as keyof FsTicket["interruptions"]
                    ]
                  : true
            );
          })
      );
    };

    if (tickets.length > 0 && currStep !== 6) {
      updateTicketsCount();
    }
  }, [
    selectedDpts,
    machines,
    problems,
    dateRange,
    currStep,
    tickets,
    extras,
    shifts,
  ]);

  if (biId) {
    return <BIDetailsPage id={biId} />;
  }

  return (
    <PageContainer
      title="Business Intelligence"
      subtitle={
        <Badge
          status={filteredTickets?.length === 0 ? "error" : "processing"}
          text={
            <Typography.Text
              italic={filteredTickets?.length !== 0}
              type={filteredTickets?.length === 0 ? "danger" : "secondary"}
            >
              {filteredTickets === undefined
                ? "Calculando..."
                : filteredTickets.length === 0
                ? "Nenhuma OS"
                : `${filteredTickets.length} OS até agora`}
            </Typography.Text>
          }
        />
      }
    >
      <BISteps
        current={currStep}
        setterFn={setCurrStep}
        onReset={() => resetForm()}
        preventNextStep={nextBtnDisabled()}
        steps={[
          {
            title: "Setores",
            description: `${
              selectedDpts ? selectedDpts.length : "0"
            } selecionados`,
            children: (
              <BIStepSelect
                items={[
                  {
                    label: "SETORES",
                    options: departments.map((dpt) => ({
                      value: dpt.id,
                      label: dpt.name,
                    })),
                    value: selectedDpts,
                    handleChange: (val) => setSelectedDpts(val),
                    isLoading: isGlobalLoading,
                    tooltip: "Selecione os setores a serem incluídos no BI",
                  },
                ]}
              />
            ),
          },
          {
            title: "Máquinas",
            description: `${
              machines
                ? Object.values(machines).reduce(
                    (sum, machinesByDpt) => sum + machinesByDpt.length,
                    0
                  )
                : "0"
            } selecionadas`,
            children: (
              <BIStepSelect
                items={selectedDpts.map((dpt) => ({
                  label: departments
                    .find((d) => d.id === dpt)!
                    .name.toUpperCase(),
                  value: machines[dpt],
                  handleChange: (values) =>
                    setMachines((currMachines) => ({
                      ...currMachines,
                      [dpt]: values,
                    })),
                  options: Object.values(
                    departments.find((d) => d.id === dpt)!.machines
                  )
                    .sort((a, b) => a.number - b.number)
                    .map((machine) => ({
                      value: machine.id,
                      label: machine.name,
                    })),
                  tooltip: `Selecione as máquinas do setor "${
                    departments.find((d) => d.id === dpt)!.name
                  }" a serem incluídas no BI`,
                }))}
              />
            ),
          },
          {
            title: "Turnos",
            description: `${
              shifts
                ? Object.values(shifts).reduce(
                    (sum, shiftsByDpt) => sum + shiftsByDpt.length,
                    0
                  )
                : "0"
            } selecionados`,
            children: (
              <BIStepSelect
                items={selectedDpts.map((dpt) => ({
                  label: departments
                    .find((d) => d.id === dpt)!
                    .name.toUpperCase(),
                  value: shifts[dpt],
                  handleChange: (values) =>
                    setShifts((currShifts) => ({
                      ...currShifts,
                      [dpt]: values,
                    })),
                  options: ["A", "B", "C"].map((shift) => ({
                    value: shift,
                    label: `Turno ${shift}`,
                  })),
                  tooltip: (
                    <>
                      Turno A – 05:20-13:40
                      <br />
                      Turno B – 13:40-22:00
                      <br />
                      Turno C – 22:00-05:20
                    </>
                  ),
                }))}
              />
            ),
          },
          {
            title: "Problemas",
            description: `${
              problems
                ? Object.values(problems).reduce(
                    (sum, problemsByDpt) => sum + problemsByDpt.length,
                    0
                  )
                : "0"
            } selecionados`,
            children: (
              <BIStepSelect
                items={selectedDpts.map((dpt) => ({
                  label: departments
                    .find((d) => d.id === dpt)!
                    .name.toUpperCase(),
                  value: problems[dpt],
                  handleChange: (values) =>
                    setProblems((currProblems) => ({
                      ...currProblems,
                      [dpt]: values,
                    })),
                  options: departments.find((d) => d.id === dpt)!.manutIssues
                    ? departments
                        .find((d) => d.id === dpt)!
                        .manutIssues!.map((problem) => ({
                          value: problem,
                          label: problem,
                        }))
                    : [],
                  tooltip: `Selecione os problemas do setor "${
                    departments.find((d) => d.id === dpt)!.name
                  }" a serem incluídos no BI`,
                }))}
              />
            ),
          },
          {
            title: "Período",
            description:
              dateRange[0].isSame("01/01/2000") &&
              dateRange[1].isSame(new Date(), "day")
                ? "Desde o início"
                : `${
                    dateRange[0].isSame("01/01/2000")
                      ? "Início"
                      : dateRange[0].format(
                          dateRange[0].year() !== dateRange[1].year()
                            ? "DD/MM/YY"
                            : "DD/MM"
                        )
                  }–${
                    dateRange[1].isSame(new Date(), "day")
                      ? "Hoje"
                      : dateRange[1].format(
                          dateRange[0].year() !== dateRange[1].year()
                            ? "DD/MM/YY"
                            : "DD/MM"
                        )
                  }`,
            children: (
              <BIStepDatePicker
                label="INTERVALO DE TEMPO"
                startDate={dateRange[0]}
                endDate={dateRange[1]}
                handleChange={(values) => {
                  setDateRange(values);
                }}
              />
            ),
          },
          {
            title: "Extras",
            description: "Concluído",
            children: <BIStepExtras value={extras} onChange={setExtras} />,
          },
          {
            title: "Emissão",
            description: "",
            children: (
              <BIStepReport
                tickets={filteredTickets}
                departments={selectedDpts}
                machines={machines}
                shifts={shifts}
                problems={problems}
                dateRange={Report.makeDateRangeInclusive(dateRange)}
                extras={extras}
              />
            ),
          },
        ]}
      />
    </PageContainer>
  );
};

export default BIPage;
