import jsPDF from "jspdf";

export default class ReportDoc {
  private doc: jsPDF;
  private _page = { w: 297, h: 210 };
  private _cursor = { x: 0, y: 0 };

  constructor() {
    this.doc = new jsPDF({
      format: "a4",
      orientation: "landscape",
      compress: true,
    });

    this.addCoverLayout();
  }

  get cursor() {
    return this._cursor;
  }

  get page() {
    return this._page;
  }

  save(fileName: string) {
    this.addPageNumbers();
    this.doc.save(`${fileName}.pdf`);
  }

  addText(
    text: string | number,
    x: number,
    y: number,
    align: "left" | "center" | "right",
    options?: {
      fontStyle?: "regular" | "bold" | "italic" | "bolditalic";
      fontSize?: number;
      textColor?: string;
    }
  ) {
    this.doc.setFont("Helvetica", "");
    this.doc.setFontSize(options?.fontSize || 7);
    this.doc.setTextColor(options?.textColor || "#333");
    if (options?.fontStyle) {
      switch (options.fontStyle) {
        case "bold":
          this.doc.setFont("Helvetica", "Bold");
          break;
        case "italic":
          this.doc.setFont("Helvetica", "Oblique");
          break;
      }
    }

    this.doc.text(text.toString(), x, y, { baseline: "top", align: align });

    return this.moveTo(x, y);
  }

  addImage(
    fileNameOrDataURI: string,
    { x, y, w, h }: { x: number; y: number; w: number; h: number },
    options?: { generated?: boolean }
  ) {
    if (options?.generated) {
      this.doc.addImage(fileNameOrDataURI, x, y, w, h);
    } else {
      const img = new Image();
      img.src = `assets/reports/${fileNameOrDataURI}.png`;
      this.doc.addImage(img, "png", x, y, w, h);
    }

    return this.moveTo(x, y);
  }

  newPage({ docId }: { docId: string }) {
    this.doc.addPage("a4", "landscape");
    this.addPageLayout();
    return this.addText(docId, this.page.w - 20, 2.027, "right", {
      fontStyle: "italic",
      fontSize: 10,
      textColor: "#EEE",
    });
  }

  private addCoverLayout() {
    return this.addImage("report-cover-layout", {
      x: 0,
      y: 0,
      w: this.page.w,
      h: this.page.h,
    });
  }

  private addPageLayout() {
    return this.addImage("report-page-layout", {
      x: 0,
      y: 0,
      w: this.page.w,
      h: this.page.h,
    });
  }

  private addPageNumbers() {
    const totalPages = this.doc.getNumberOfPages();
    for (let i = 2; i <= totalPages; i++) {
      this.doc.setPage(i);
      this.addText(
        `Pág. ${i}/${totalPages}`,
        this.page.w - 20,
        204.066,
        "right",
        { fontSize: 10, fontStyle: "italic", textColor: "#EEE" }
      );
    }
  }

  private moveTo(x: number, y: number) {
    this._cursor = { x, y };
    return this._cursor;
  }
}
