import { Modal } from "antd";
import React from "react";
import { componentStyles } from "../utils";
import LoadingIndicator from "./LoadingIndicator";

type GlobalLoadingProps = {
  show: boolean;
};

const GlobalLoading: React.FC<GlobalLoadingProps> = ({ show }) => {
  return (
    <Modal
      visible={show}
      closable={false}
      footer={null}
      bodyStyle={styles.modalBody}
    >
      <LoadingIndicator />
    </Modal>
  );
};

const styles = componentStyles({
  modalBody: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
});

export default GlobalLoading;
