import firebase from "firebase";
import moment from "moment";
import { FsReport, FsTicket } from "../types";
import {
  calcMachineStoppedHours,
  calcProductionStoppedHours,
  calcTicketLoss,
  getTicketHourlyProdRate,
} from "./tickets/calculators";
import {
  getTicketStatusStyles,
  idToName,
  translateMachineStoppedHours,
} from "./translators";

export const generateCSV = async (config: {
  dateRange: [moment.Moment, moment.Moment];
  tickets?: FsTicket[];
}) => {
  document.body.style.cursor = "wait";

  if (!config.tickets) {
    const ticketsSnapshot = await firebase
      .firestore()
      .collection("tickets")
      .get();
    config.tickets = ticketsSnapshot.docs
      .map((doc) => doc.data() as FsTicket)
      .filter((ticket) =>
        moment(ticket.createdAt).isBetween(...config.dateRange)
      );
  }

  const reportId =
    (await firebase.firestore().collection("manut-reports").get()).docs
      .map((doc) => doc.data() as FsReport)
      .sort((docA, docB) => docB.internalId - docA.internalId)[0]?.internalId +
      1 || 1;

  const fsReport: FsReport = {
    type: "tickets",
    internalId: reportId,
    dateRange: [
      firebase.firestore.Timestamp.fromDate(config.dateRange[0].toDate()),
      firebase.firestore.Timestamp.fromDate(config.dateRange[1].toDate()),
    ],
    tickets: config.tickets,
  };

  await firebase.firestore().collection("manut-reports").add(fsReport);

  config.tickets.forEach((t) => {
    Object.entries(t).forEach(([key, val]) => {
      if (typeof val === "string") {
        // @ts-ignore
        t[key] = val.replace(/,/g, "").replace(/\n/g, " ");
      }
    });
  });

  const csvLines = [
    "Nº,Data de abertura,Status,Setor,Equipamento,Manutentor,Hora chamada,Início da ocorrência,Fim da ocorrência,Hora encerrada,Parou máquina?,Parou linha?,Parou produção?,Descrição do problema,Descrição do serviço,Total de horas trabalhadas,Horas para o início do serviço,Tempo de máquina parada,Tempo de produção parada,OS encerrada?,Tipo de manutenção,Tempo de manutenção sem parada de máquina,Produção Máquina / hora,Perda est.",
    ...config.tickets.map(
      (t) =>
        `${t.id},${moment(t.createdAt).format(
          "DD/MM/YY"
        )},${getTicketStatusStyles(t.status).text.toUpperCase()},${t.dpt},M${
          t.machine
        },${t.assignedMaintainer || "—"},${moment(t.createdAt).format(
          "DD/MM/YY HH:mm:ss"
        )},${
          t.acceptedAt ? moment(t.acceptedAt).format("DD/MM/YY HH:mm:ss") : ""
        },${t.solvedAt ? moment(t.solvedAt).format("DD/MM/YY HH:mm:ss") : ""},${
          t.closedAt ? moment(t.closedAt).format("DD/MM/YY HH:mm:ss") : ""
        },${t.interruptions.equipment ? "SIM" : "NÃO"},${
          t.interruptions.line ? "SIM" : "NÃO"
        },${
          t.interruptions.production === undefined
            ? "—"
            : t.interruptions.production
            ? "SIM"
            : "NÃO"
        },${t.description},${t.solution ? t.solution : ""},${
          t.solvedAt && t.acceptedAt
            ? translateMachineStoppedHours(
                moment(t.solvedAt).diff(t.acceptedAt, "hours", true)
              )
            : ""
        },${
          t.acceptedAt
            ? translateMachineStoppedHours(
                moment(t.acceptedAt).diff(t.createdAt, "hours", true)
              )
            : ""
        },${translateMachineStoppedHours(
          calcMachineStoppedHours(t)
        )},${translateMachineStoppedHours(calcProductionStoppedHours(t))},${
          t.status === "closed" ? "SIM" : "NÃO"
        },${idToName(t.maintenanceType)},${
          calcMachineStoppedHours(t) === 0 && t.solvedAt && t.acceptedAt
            ? translateMachineStoppedHours(
                moment(t.solvedAt).diff(t.acceptedAt, "hours", true)
              )
            : ""
        },${getTicketHourlyProdRate(t)},${calcTicketLoss(t)}`
    ),
  ].map((line) =>
    line
      .split("")
      .map((char) => {
        const special =
          "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ".split(
            ""
          );
        if (special.includes(char)) {
          return "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr".split(
            ""
          )[special.findIndex((val) => val === char)];
        } else {
          return char;
        }
      })
      .join("")
  );

  console.log(csvLines);

  const csvContent = "data:text/csv;charset=utf-8," + csvLines.join("\n");
  const encodedURI = encodeURI(csvContent);

  const anchorEl = document.createElement("a");
  anchorEl.setAttribute("href", encodedURI);
  anchorEl.setAttribute("download", "Relatório Manutenção.csv");
  document.body.appendChild(anchorEl); // Required for FF

  anchorEl.click();

  document.body.style.cursor = "default";

  return config.tickets;
};
