import { DataActionSetDepartments, DataActionSetLoading } from "../../types";

export const setDepartments = (
  departments: DataActionSetDepartments["payload"]["departments"]
): DataActionSetDepartments => {
  return {
    type: "DATA_SET_DEPARTMENTS",
    payload: { departments },
  };
};

export const setDataLoading = (loading: boolean): DataActionSetLoading => {
  return {
    type: "DATA_SET_LOADING",
    payload: { loading },
  };
};
