import moment from "moment";
import store from "../store";
import { FsBI, FsTicket } from "../types";
import { countInAllDepartments } from "./counters";

export const translateDpt = (dptId: string) => {
  return (
    {
      "kit-automatico": "Kit Automático",
      flexografia: "Flexografia",
      extrusao: "Extrusão",
      "corte-solda-luva": "Corte & Solda (Luva)",
      "corte-solda-saco": "Corte & Solda (Saco)",
      reciclagem: "Reciclagem",
      dobra: "Dobra",
      "off-set": "Off-Set",
      corte: "Corte",
      "super-kit": "Super Kit",
    }[dptId] || dptId
  );
};

export const getTicketStatusStyles = (
  status: FsTicket["status"]
): { text: string; bgColor: string; color: string } => {
  switch (status) {
    case "pending":
      return { text: "Pendente", bgColor: "#F2D602", color: "black" };
    case "solving":
      return { text: "Resolvendo", bgColor: "#FF9E1A", color: "black" };
    case "solved":
      return { text: "Solucionada", bgColor: "#51E898", color: "black" };
    case "closed":
      return { text: "Encerrada", bgColor: "#60BE4E", color: "white" };
    case "refused":
      return { text: "Recusada", bgColor: "#EB5A46", color: "black" };
  }
};

export const idToName = (id: string) => {
  return (
    {
      mecanica: "Mecânica",
      eletrica: "Elétrica",
      predial: "Predial",
      preventiva: "Preventiva",
      corretiva: "Corretiva",
      preditiva: "Preditiva",
      machineAdjustment: "Ajuste de máquina",
    }[id] || id
  );
};

export const translateMachineStoppedHours = (hours: number) => {
  if (hours === 0) {
    return "—";
  }

  const duration = moment.duration(hours, "h");

  return `${
    Math.floor(duration.asHours()) === 0
      ? ""
      : `${Math.floor(duration.asHours())}h`
  }${duration.minutes() === 0 ? "" : `${duration.minutes()}m`}${
    duration.seconds() === 0 ? "" : `${duration.seconds()}s`
  }`;
};

export const translateReportInputs = (
  inputs: Pick<
    FsBI,
    "departments" | "machines" | "shifts" | "problems" | "dateRange"
  >
) => {
  function translateQty(
    currQty: number,
    totalQty: number,
    singularWord: string,
    pluralWord: string,
    isFeminine?: boolean
  ): { short: string; long: string } {
    let short: string;
    let long: string;
    if (currQty === totalQty) {
      short = isFeminine ? "Todas" : "Todos";
      long = isFeminine ? `Todas as ${pluralWord}` : `Todos os ${pluralWord}`;
    } else if (currQty === 0) {
      short = isFeminine ? "Nenhuma" : "Nenhum";
      long = isFeminine ? `Nenhuma ${singularWord}` : `Nenhum ${singularWord}`;
    } else {
      short = currQty.toString();
      if (currQty === 1) {
        long = `1 ${singularWord}`;
      } else {
        long = `${currQty} ${pluralWord}`;
      }
    }
    return { short, long };
  }

  const translations: Record<
    "departments" | "machines" | "shifts" | "problems" | "dateRange",
    { short: string; long: string } | undefined
  > = {
    departments: undefined,
    machines: undefined,
    shifts: undefined,
    problems: undefined,
    dateRange: undefined,
  };
  if (inputs.departments) {
    translations.departments = translateQty(
      inputs.departments.length,
      store.getState().data.departments.length,
      "setor",
      "setores"
    );
  }
  if (inputs.machines) {
    translations.machines = translateQty(
      countInAllDepartments(inputs.machines),
      store
        .getState()
        .data.departments.reduce(
          (acc, dpt) => acc + Object.keys(dpt.machines).length,
          0
        ),
      "máquina",
      "máquinas",
      true
    );
  }
  if (inputs.shifts) {
    translations.shifts = translateQty(
      countInAllDepartments(inputs.shifts),
      store.getState().data.departments.length * 3,
      "turno",
      "turnos"
    );
  }
  if (inputs.problems) {
    translations.problems = translateQty(
      countInAllDepartments(inputs.problems),
      store
        .getState()
        .data.departments.reduce(
          (acc, dpt) => acc + (dpt.manutIssues ? dpt.manutIssues.length : 0),
          0
        ),
      "problema",
      "problemas"
    );
  }
  if (inputs.dateRange) {
    let translation = "";
    const momentDateRange = [
      moment(inputs.dateRange[0].toDate()),
      moment(inputs.dateRange[1].toDate()),
    ];
    if (momentDateRange[0].isSame("01/01/2000")) {
      if (momentDateRange[1].isSame(new Date(), "day")) {
        translation = "Desde o início";
      } else {
        translation = `Início–${momentDateRange[1].format(
          momentDateRange[0].year() !== momentDateRange[1].year()
            ? "DD/MM/YY"
            : "DD/MM"
        )}`;
      }
    } else if (
      momentDateRange[0].isSame(new Date(), "day") &&
      momentDateRange[1].isSame(new Date(), "day")
    ) {
      translation = "Hoje";
    } else if (
      momentDateRange[0].isSame(moment().subtract(1, "day"), "day") &&
      momentDateRange[1].isSame(moment().subtract(1, "day"), "day")
    ) {
      translation = "Ontem";
    } else {
      translation = `${momentDateRange[0].format(
        momentDateRange[0].year() !== momentDateRange[1].year()
          ? "DD/MM/YY"
          : "DD/MM"
      )}–${momentDateRange[1].format(
        momentDateRange[0].year() !== momentDateRange[1].year()
          ? "DD/MM/YY"
          : "DD/MM"
      )}`;
    }
    translations.dateRange = { short: translation, long: translation };
  }

  return translations;
};

export const translatePriority = (priority: FsTicket["priority"]): string => {
  switch (priority) {
    case "low":
      return "BAIXA";
    case "medium":
      return "MÉDIA";
    case "high":
      return "ALTA";
    default:
      return "Indefinida";
  }
};
