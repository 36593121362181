import { useSelector } from "react-redux";
import { DataState, RootState } from "../types";

const useData = () => {
  const data = useSelector<RootState, DataState>((state) => state.data);

  return data;
};

export default useData;
