import firebase from "firebase";
import moment, { Moment } from "moment";
import { FsTicket, ReportConfig } from "../types";
import {
  calcProductionStoppedHours,
  calcTicketLoss,
  countInAllDepartments,
  generateCSV,
  getDepartmentById,
  problemIsOthers,
  translateDpt,
  translateMachineStoppedHours
} from "../utils";
import ReportDoc from "./ReportDoc";
export default class Report {
  private docId = "BI-MANUT-001";
  private reportId: string;
  private issuedAt: moment.Moment;

  private doc: ReportDoc;

  constructor(
    reportId: number,
    private tickets: FsTicket[],
    private config: {
      qrCodeURI: string;
      issuedAt: firebase.firestore.Timestamp;
      dateRange: ReportConfig["dateRange"];
      extras: ReportConfig["extras"];
      departments: ReportConfig["departments"];
      machines: ReportConfig["machines"];
      shifts: ReportConfig["shifts"];
      problems: ReportConfig["problems"];
    }
  ) {
    this.reportId = "MANUT-" + reportId.toString().padStart(4, "0");
    this.issuedAt = moment(config.issuedAt.toDate());

    this.doc = new ReportDoc();

    this.populate();
  }

  static makeDateRangeInclusive(
    dateRange: [moment.Moment, moment.Moment]
  ): [moment.Moment, moment.Moment] {
    return [
      moment(dateRange[0]).subtract(1, "day").endOf("day"),
      moment(dateRange[1]).endOf("day"),
    ];
  }

  static async createTicketsReport(config: {
    dateRange: [Moment, Moment];
    tickets?: FsTicket[];
  }) {
    return generateCSV(config);
  }

  populate() {
    document.body.style.cursor = "wait";

    const lossesPerProblem: { [problem: string]: number } = {};
    this.tickets.forEach((t) => {
      const problemDescription = `${t.description} (${
        getDepartmentById(t.username)?.name
      })`;
      if (!lossesPerProblem[problemDescription]) {
        lossesPerProblem[problemDescription] = 0;
      }
      lossesPerProblem[problemDescription] += calcTicketLoss(t);
    });
    const mostCriticalProblem = Object.entries(lossesPerProblem).sort(
      ([_, lossesA], [_B, lossesB]) => lossesB - lossesA
    )[0][0];

    this.populateCover({
      docId: this.docId,
      department: "Manutenção",
      issuedAt: this.issuedAt.format("DD/MM/YYYY HH:mm"),
      reportId: this.reportId,
      summary: {
        dateRange: `${this.config.dateRange[0].format(
          "DD/MM/YY"
        )}–${this.config.dateRange[1].format("DD/MM/YY")}`,
        qtyDepartments: this.config.departments.length,
        qtyMachines: countInAllDepartments(this.config.machines),
        qtyShifts: countInAllDepartments(this.config.shifts),
        qtyProblems: countInAllDepartments(this.config.problems),
        qtyTickets: this.tickets.length,
      },
      results: {
        mostCriticalProblem,
        totalStoppedTime: translateMachineStoppedHours(
          this.tickets.reduce(
            (sum, t) => sum + calcProductionStoppedHours(t),
            0
          )
        ),
        totalLoss: new Intl.NumberFormat("pt-BR", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(this.tickets.reduce((sum, t) => sum + calcTicketLoss(t), 0)),
      },
    });

    const ticketsPerDpt: { [dptId: string]: FsTicket[] } = {};
    this.tickets.forEach((ticket) => {
      if (!ticketsPerDpt[ticket.username]) {
        ticketsPerDpt[ticket.username] = [];
      }
      ticketsPerDpt[ticket.username].push(ticket);
    });

    Object.entries(ticketsPerDpt)
      .map(
        ([dptId, tickets]) =>
          [
            dptId,
            tickets.sort((tA, tB) =>
              tA.description.localeCompare(tB.description)
            ),
          ] as [string, FsTicket[]]
      )
      .sort(
        ([_, ticketsA], [_B, ticketsB]) =>
          ticketsB.reduce((sum, t) => sum + calcTicketLoss(t), 0) -
          ticketsA.reduce((sum, t) => sum + calcTicketLoss(t), 0)
      )
      .forEach(([dptId, dptTickets], idx) => {
        this.doc.newPage({ docId: this.docId });
        this.addPageHeader(`${idx + 1}. ${translateDpt(dptId)}`);

        const lossesPerMachine: { [machineId: string]: number } = {};
        dptTickets.forEach((ticket) => {
          if (!lossesPerMachine[ticket.machine]) {
            lossesPerMachine[ticket.machine] = 0;
          }
          lossesPerMachine[ticket.machine] += calcTicketLoss(ticket);
        });
        const orderedMachineIds = [...Object.entries(lossesPerMachine)]
          .reverse()
          .sort(
            ([_, occurrencesA], [_B, occurrencesB]) =>
              occurrencesA - occurrencesB
          )
          .map(([machineId]) => machineId);

        this.addTableHeader(orderedMachineIds, dptId);

        const lossesPerProblem: { [problemDescription: string]: number } = {};
        dptTickets
          .filter((t) => this.config.extras.expandOthers || !problemIsOthers(t))
          .forEach((ticket) => {
            if (!lossesPerProblem[ticket.description]) {
              lossesPerProblem[ticket.description] = 0;
            }
            lossesPerProblem[ticket.description] += calcTicketLoss(ticket);
          });
        const orderedProblems = [...Object.entries(lossesPerProblem)]
          .sort(
            ([_, occurrencesA], [_B, occurrencesB]) =>
              occurrencesB - occurrencesA
          )
          .map(([problem]) => problem);

        const otherProblems = this.config.extras.expandOthers
          ? []
          : dptTickets.filter((t) => problemIsOthers(t));

        const totalsPerProblem: {
          [problem: string]: {
            perMachine: number[];
            line: number;
            hours: number;
            loss: number;
          };
        } = {};
        let totalsPerMachine: {
          id: string;
          occurrences: number;
          hours: number;
          loss: number;
        }[] = orderedMachineIds.map((machineId) => ({
          id: machineId,
          occurrences: 0,
          hours: 0,
          loss: 0,
        }));

        orderedProblems.forEach((problemDescription, idx) => {
          const problemTickets = dptTickets.filter(
            (ticket) => ticket.description === problemDescription
          );

          const totals = { perMachine: [0], line: 0, hours: 0, loss: 0 };
          totals.perMachine = orderedMachineIds.map((machineId) =>
            problemTickets.reduce(
              (sum, ticket) => sum + (ticket.machine === machineId ? 1 : 0),
              0
            )
          );
          totals.line = totals.perMachine.reduce((sum, t) => sum + t, 0);
          totals.hours = problemTickets.reduce(
            (sum, t) => sum + calcProductionStoppedHours(t),
            0
          );
          totals.loss = problemTickets.reduce(
            (sum, t) => sum + calcTicketLoss(t),
            0
          );

          totalsPerProblem[problemDescription] = totals;

          totalsPerMachine = totalsPerMachine.map(
            (machineTotals, machineIdx) => ({
              ...machineTotals,
              occurrences:
                machineTotals.occurrences +
                problemTickets.reduce(
                  (acc, t) => acc + (t.machine === machineTotals.id ? 1 : 0),
                  0
                ),
              hours:
                machineTotals.hours +
                problemTickets.reduce(
                  (acc, t) =>
                    acc +
                    (t.machine === machineTotals.id
                      ? calcProductionStoppedHours(t)
                      : 0),
                  0
                ),
              loss:
                machineTotals.loss +
                problemTickets.reduce(
                  (acc, t) =>
                    acc +
                    (t.machine === machineTotals.id ? calcTicketLoss(t) : 0),
                  0
                ),
            })
          );

          this.addTableLine({
            problemDescription,
            idx,
            totals,
            isLast:
              idx === orderedProblems.length - 1 && otherProblems.length === 0,
            dpt: dptId,
          });
        });

        const otherProblemsTotals = {
          perMachine: [0],
          line: 0,
          hours: 0,
          loss: 0,
        };
        otherProblemsTotals.perMachine = orderedMachineIds.map((machineId) =>
          otherProblems.reduce(
            (sum, ticket) => sum + (ticket.machine === machineId ? 1 : 0),
            0
          )
        );
        otherProblemsTotals.line = otherProblemsTotals.perMachine.reduce(
          (sum, t) => sum + t,
          0
        );
        otherProblemsTotals.hours = otherProblems.reduce(
          (sum, t) => sum + calcProductionStoppedHours(t),
          0
        );
        otherProblemsTotals.loss = otherProblems.reduce(
          (sum, t) => sum + calcTicketLoss(t),
          0
        );
        if (otherProblems.length > 0) {
          this.addTableLine({
            problemDescription: "Outros",
            idx: orderedProblems.length,
            totals: otherProblemsTotals,
            isLast: true,
            dpt: dptId,
          });
          totalsPerProblem["Outros"] = otherProblemsTotals;
        }

        this.addTableFooter(totalsPerProblem, totalsPerMachine);
      });

    document.body.style.cursor = "default";
  }

  async pdf() {
    document.body.style.cursor = "wait";
    this.doc.save("Relatório Manutenção");
    document.body.style.cursor = "default";
  }

  async csv() {
    document.body.style.cursor = "wait";
    await Report.createTicketsReport({
      dateRange: this.config.dateRange,
      tickets: this.tickets,
    });
    document.body.style.cursor = "default";
  }

  private populateCover(coverData: {
    docId: string;
    department: string;
    issuedAt: string;
    reportId: string;
    summary: {
      dateRange: string;
      qtyDepartments: number;
      qtyMachines: number;
      qtyShifts: number;
      qtyProblems: number;
      qtyTickets: number;
    };
    results: {
      mostCriticalProblem: string;
      totalStoppedTime: string;
      totalLoss: string;
    };
  }) {
    const { docId, department, issuedAt, reportId, summary, results } =
      coverData;
    this.doc.addText(docId, this.doc.page.w - 20, 2.027, "right", {
      fontStyle: "italic",
      fontSize: 10,
      textColor: "#EEE",
    });
    this.doc.addText(department, 50.678, 46.968, "left", { fontSize: 10 });
    this.doc.addText(issuedAt, 45.744, 52.949, "left", { fontSize: 10 });
    this.doc.addText(reportId, 231.598, 46.968, "left", {
      fontSize: 10,
      fontStyle: "italic",
    });

    Object.values(summary).forEach((val, idx) => {
      this.doc.addText(val, 275, 82.631 + 7 * idx, "right", { fontSize: 10 });
    });

    Object.values(results).forEach((val, idx) => {
      this.doc.addText(val, 275, 125.87 + 7 * idx, "right", {
        fontSize: 10,
        fontStyle: "bold",
      });
    });

    this.doc.addImage(
      this.config.qrCodeURI,
      {
        x: 260.969,
        y: 44.691,
        w: 14,
        h: 14,
      },
      { generated: true }
    );
  }

  private addPageHeader(title: string) {
    this.doc.addImage("report-page-header-bg", {
      x: 19.969,
      y: 19.605,
      w: 257,
      h: 7,
    });
    this.doc.addText(title, 25.969, 20.985, "left", {
      fontSize: 12,
      fontStyle: "bold",
      textColor: "white",
    });
  }

  private addTableHeader(orderedMachineIds: string[], dpt?: string) {
    this.doc.addImage("table-header-no-column", {
      x: 21.969,
      y: 29.353,
      w: 253,
      h: 3.486,
    });
    this.doc.addImage("table-header-column-div", {
      x: 255.251,
      y: 28.8,
      w: 0.2,
      h: 4,
    });
    this.doc.addImage("table-header-column-div", {
      x: 235.88,
      y: 28.8,
      w: 0.2,
      h: 4,
    });
    this.doc.addImage("table-header-column-div", {
      x: 216.509,
      y: 28.8,
      w: 0.2,
      h: 4,
    });
    orderedMachineIds.forEach((machineId, idx) => {
      const leftDivX = 204.395 - 2.1985 - idx * 14.511;
      this.doc.addImage("table-header-column-div", {
        x: leftDivX,
        y: 28.6,
        w: 0.2,
        h: 4,
      });
      this.doc.addText(
        `M${machineId}`,
        leftDivX + (14.511 + 0.2) / 2,
        29.353,
        "center"
      );
    });
  }

  private addTableLine({
    problemDescription,
    idx,
    totals,
    isLast,
    dpt,
  }: {
    problemDescription: string;
    idx: number;
    totals: { perMachine: number[]; line: number; hours: number; loss: number };
    isLast?: boolean;
    dpt: string;
  }) {
    let imgName = "table-line-contrast";
    if (idx === 0) {
      if (isLast) {
        imgName = "table-line-contrast-flat";
      } else {
        imgName = "table-line-contrast-first";
      }
    } else if (isLast) {
      imgName = "table-line-contrast-last";
    }
    if (idx % 2 === 0) {
      this.doc.addImage(imgName, {
        x: 21.969,
        y: 32.8 + idx * 5,
        w: 253,
        h: 5,
      });
    }

    this.doc.addText(
      problemDescription,
      27.5 - 2.1985,
      34.177 + 5 * idx,
      "left",
      {
        fontStyle:
          problemDescription === "Outros" ||
          problemIsOthers({ dpt, problem: problemDescription })
            ? "italic"
            : undefined,
      }
    );

    this.doc.addText(
      new Intl.NumberFormat("pt-BR", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(totals.loss),
      274.969 - 2.1985,
      34.177 + 5 * idx,
      "right"
    );
    this.doc.addText(
      translateMachineStoppedHours(totals.hours),
      255.598 - 2.1985,
      34.177 + 5 * idx,
      "right"
    );
    this.doc.addText(totals.line, 236.227 - 2.1985, 34.177 + 5 * idx, "right");

    totals.perMachine.forEach((machineTotal, machineTotalIdx) => {
      const leftDivX = 204.395 - 2.1985 - machineTotalIdx * 14.511;
      this.doc.addText(
        machineTotal,
        leftDivX + 14.511 - 2,
        34.177 + 5 * idx,
        "right"
      );
    });

    this.doc.addImage("table-column-div", {
      x: 216.509,
      y: 32.8 + idx * 5,
      w: 0.2,
      h: 5,
    });
  }

  private addTableFooter(
    totalsPerProblem: {
      [problem: string]: {
        perMachine: number[];
        line: number;
        hours: number;
        loss: number;
      };
    },
    totalsPerMachine: {
      id: string;
      occurrences: number;
      hours: number;
      loss: number;
    }[]
  ) {
    const numOfProblems = Object.keys(totalsPerProblem).length;
    this.doc.addImage("table-column-div", {
      x: 216.509,
      y: 32.8 + numOfProblems * 5 + 0.1,
      w: 0.2,
      h: 5,
    });
    this.doc.addImage("table-column-div", {
      x: 216.509,
      y: 32.8 + (numOfProblems + 1) * 5 + 0.1,
      w: 0.2,
      h: 5,
    });
    this.doc.addImage("table-column-div", {
      x: 216.509,
      y: 32.8 + (numOfProblems + 2) * 5 + 0.1,
      w: 0.2,
      h: 5,
    });
    this.doc.addImage("table-footer-no-column", {
      x: 21.969,
      y: 32.8 + numOfProblems * 5,
      w: 253,
      h: 15.2,
    });

    this.doc.addText(
      new Intl.NumberFormat("pt-BR", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(
        Object.values(totalsPerProblem).reduce(
          (acc, totals) => acc + totals.loss,
          0
        )
      ),
      274.969 - 2.1985,
      39.285 + numOfProblems * 5,
      "right",
      { fontStyle: "bold" }
    );
    this.doc.addText(
      translateMachineStoppedHours(
        Object.values(totalsPerProblem).reduce(
          (acc, totals) => acc + totals.hours,
          0
        )
      ),
      255.598 - 2.1985,
      39.285 + numOfProblems * 5,
      "right",
      { fontStyle: "bold" }
    );
    this.doc.addText(
      Object.values(totalsPerProblem).reduce(
        (acc, totals) => acc + totals.line,
        0
      ),
      236.227 - 2.1985,
      39.285 + numOfProblems * 5,
      "right",
      { fontStyle: "bold" }
    );

    totalsPerMachine.forEach((machineTotals, machineIdx) => {
      const leftDivX = 204.395 - 2.1985 - machineIdx * 14.511;
      this.doc.addText(
        machineTotals.occurrences,
        leftDivX + 14.511 - 2,
        34.285 + numOfProblems * 5,
        "right"
      );
      this.doc.addText(
        translateMachineStoppedHours(machineTotals.hours),
        leftDivX + 14.511 - 2,
        39.285 + numOfProblems * 5,
        "right"
      );
      this.doc.addText(
        new Intl.NumberFormat("pt-BR", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(machineTotals.loss),
        leftDivX + 14.511 - 2,
        44.285 + numOfProblems * 5,
        "right"
      );
    });
  }
}
