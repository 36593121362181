import { AuthAction, AuthState } from "../../types";

const initialState: AuthState = {
  user: undefined,
};

const reducer = (state = initialState, action: AuthAction) => {
  switch (action.type) {
    case "AUTH_SET_USER":
      return { ...state, user: action.payload.user };
    default:
      return state;
  }
};

export default reducer;
