import { Card, Statistic } from "antd";
import CONSTANTS from "../../constants";
import { componentStyles } from "../../utils";

type DashboardStatisticProps = {
  title: string;
  value: string | number;
  color: string;
  style?: React.CSSProperties;
};

const DashboardStatistic: React.FC<DashboardStatisticProps> = ({
  title,
  value,
  color,
  style,
}) => {
  const StatisticTitle: React.FC = () => {
    return (
      <div style={styles.title}>
        <div style={{ ...styles.icon, backgroundColor: color }} />
        {title}
      </div>
    );
  };

  return (
    <Card style={{ ...styles.container, ...style }}>
      <Statistic title={<StatisticTitle />} value={value} />
    </Card>
  );
};

const styles = componentStyles({
  container: {
    borderRadius: CONSTANTS.STYLE.BORDER_RADIUS.MD,
  },
  title: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    width: 10,
    height: 10,
    borderRadius: "50%",
    marginRight: CONSTANTS.STYLE.SPACING.XS,
  },
});

export default DashboardStatistic;
