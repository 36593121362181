import React from "react";
import { componentStyles } from "../utils";

type IconProps = {
  style?: React.CSSProperties;
};

const Icon: React.FC<IconProps> = ({ children, style }) => {
  return <div style={{ ...styles.iconContainer, ...style }}>{children}</div>;
};

const styles = componentStyles({
  iconContainer: {
    display: "flex",
  },
});

export default Icon;
